import React, { PureComponent } from 'react';

import getISOWeek from 'date-fns/get_iso_week';
import isWithinRange from 'date-fns/is_within_range';
import isSameDay from 'date-fns/is_same_day';

import DateInterval from './DateInterval';

import './CalendarMonth.css';

import { EMPTY_DAY, monthCalendar } from './monthCalendar';

import { Weekdays } from './Weekdays';

import isSameDayAsExcluded from './utils/isSameDayAsExcluded';
import getWeekdays from './utils/getWeekdays';
import isIntervalEdge from './utils/isIntervalEdge';
import isWithinAnInterval from './utils/isWithinAnInterval';

function defaultRenderEmptyDay(index: number) {
  return (
    <div
      className="CalendarMonth-day CalendarMonth-day--empty"
      key={`empty-${index}`}
    />
  );
}

function defaultRenderDay(day: Date) {
  return <div className="CalendarMonth-day-number">{day.getUTCDate()}</div>;
}

function defaultRenderWeekDay(weekday: string) {
  return <div className="CalendarMonth-weekday-name">{weekday}</div>;
}

function defaultRenderMonthName(monthName: string) {
  return <div className="CalendarMonth-label">{monthName}</div>;
}

function isDateToday(utcDate: Date): boolean {
  const now = new Date();
  const utcToday = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()),
  );

  return isSameDay(utcDate, utcToday);
}

interface Props {
  year: number;
  month: number;
  startDate?: Date;
  endDate?: Date;
  intervals?: DateInterval[];
  minDate?: Date;
  maxDate?: Date;
  exclusions?: Date[];
  excludedDaysOfWeek?: string[];
  weekStartsOn?: number;
  locale?: string;
  allDaysAvailable?: boolean;
  hideMonthName?: boolean;
  showWeekNumber?: boolean;
  onDayClicked?: (d: Date) => void;
  onWeekNumberClicked?: (wn: number) => void;
  onWeekDayClicked?: (wd: number) => void;
  className?: string;
  dayClass?: string;
  availableClass?: string;
  excludedClass?: string;
  betweenClass?: string;
  betweenEdgeClass?: string;
  weekdayExcludedClass?: string;
  startClass?: string;
  endClass?: string;
  todayClass?: string;
  clickableClass?: string;
  isClickable?: (p: Props, d: Date) => boolean;
  renderDay?: (d: Date) => React.ReactNode;
  renderEmptyDay?: (index: number) => React.ReactNode;
  renderWeekDay?: (wd: string) => React.ReactNode;
  renderMonthName?: (monthName: string, year: number) => React.ReactNode;
}

export class CalendarMonth extends PureComponent<Props> {
  static defaultProps = {
    minDate: new Date(0),
    maxDate: new Date(3000, 0),
    exclusions: [],
    excludedDaysOfWeek: [],
    className: '',
    dayClass: '',
    locale: 'en-GB',
    weekStartsOn: 1,
    excludedClass: 'CalendarMonth-day--excluded',
    availableClass: 'CalendarMonth-day--available',
    betweenClass: 'CalendarMonth-day--between',
    weekdayExcludedClass: 'CalendarMonth-day--weekday-excluded',
    betweenEdgeClass: 'CalendarMonth-day--edge',
    startClass: 'CalendarMonth-day--start',
    endClass: 'CalendarMonth-day--end',
    todayClass: 'CalendarMonth-day--today',
    clickableClass: 'CalendarMonth-day--clickable',
    isClickable: (props: Props, date: Date) =>
      isWithinRange(date, props.minDate!, props.maxDate!),
    onWeekDayClicked: () => {},
    renderDay: defaultRenderDay,
    renderEmptyDay: defaultRenderEmptyDay,
    renderWeekDay: defaultRenderWeekDay,
    renderMonthName: defaultRenderMonthName,
  };

  constructor(props: Readonly<Props>) {
    super(props);

    this.renderDay = this.renderDay.bind(this);
    this.renderWeek = this.renderWeek.bind(this);
  }

  renderDay(day: typeof EMPTY_DAY | Date, index: number) {
    const {
      startDate,
      endDate,
      intervals,
      minDate,
      maxDate,
      onDayClicked,
      exclusions,
      dayClass,
      availableClass,
      betweenClass,
      weekdayExcludedClass,
      excludedClass,
      betweenEdgeClass,
      startClass,
      endClass,
      todayClass,
      clickableClass,
      isClickable,
      renderDay,
      renderEmptyDay,
      excludedDaysOfWeek,
    } = this.props;

    if (day === EMPTY_DAY) {
      return renderEmptyDay!(index);
    }

    const currentDayNameShort = day
      .toLocaleDateString('en-GB', {
        weekday: 'short',
        timeZone: 'UTC',
      })
      .toUpperCase();

    const isWeekdayExcluded = excludedDaysOfWeek!.includes(currentDayNameShort);

    const isBetween =
      startDate && endDate ? isWithinRange(day, startDate, endDate) : false;

    const isBetweenInterval = intervals
      ? isWithinAnInterval(day, intervals)
      : false;

    const isAvailable =
      minDate && maxDate ? isWithinRange(day, minDate, maxDate) : false;

    const isStart = startDate ? isSameDay(day, startDate) : false;
    const isEnd = endDate ? isSameDay(day, endDate) : false;

    const isIntervalStart = intervals
      ? isIntervalEdge(day, intervals, 'start')
      : false;

    const isIntervalEnd = intervals
      ? isIntervalEdge(day, intervals, 'end')
      : false;

    const isEdge = isStart || isIntervalStart || isEnd || isIntervalEnd;

    const isToday = isDateToday(day as Date);

    const isExcluded = exclusions!.some(isSameDayAsExcluded(day));

    const isExcludedClass = isExcluded ? excludedClass : '';
    const isAvailableClass = isAvailable ? availableClass : '';
    const isBetweenClass = isBetween || isBetweenInterval ? betweenClass : '';
    const isEdgeClass = isEdge ? betweenEdgeClass : '';
    const isStartClass = isStart || isIntervalStart ? startClass : '';
    const isEndClass = isEnd || isIntervalEnd ? endClass : '';
    const isWeekdayExcludedClass = isWeekdayExcluded
      ? weekdayExcludedClass
      : '';

    const isTodayClass = isToday ? todayClass : '';

    const isDayClickable = isClickable!(this.props, day);
    const isClickableClass = isDayClickable ? clickableClass : '';

    const onClick = () => {
      if (isAvailable && onDayClicked instanceof Function) {
        onDayClicked(day);
      }
    };

    const className = `CalendarMonth-day ${isWeekdayExcludedClass} ${dayClass} ${isExcludedClass} ${isAvailableClass} ${isBetweenClass} ${isEdgeClass} ${isStartClass} ${isEndClass} ${isClickableClass} ${isTodayClass}`;

    return (
      <div className={className} onClick={onClick} key={day.getUTCDate()}>
        {renderDay!(day)}
      </div>
    );
  }

  renderWeekNumber(week: (typeof EMPTY_DAY | Date)[]) {
    const { onWeekNumberClicked } = this.props;

    const onlyDates = week.filter(day => day instanceof Date);
    const weekNumber = getISOWeek(onlyDates[0]);

    const onClick =
      onWeekNumberClicked && onWeekNumberClicked instanceof Function
        ? () => onWeekNumberClicked(weekNumber)
        : () => {};

    return (
      <div
        className="CalendarMonth-week-number"
        key={weekNumber}
        onClick={() => onClick()}
      >
        {weekNumber}
      </div>
    );
  }

  renderWeek(week: (typeof EMPTY_DAY | Date)[], key: number) {
    const { weekStartsOn, showWeekNumber } = this.props;

    const renderedDays = week.map(this.renderDay);

    const weekNumber =
      weekStartsOn === Weekdays.MONDAY && showWeekNumber
        ? this.renderWeekNumber(week)
        : null;

    return (
      <div className="CalendarMonth-week" key={key}>
        {weekNumber}
        {renderedDays}
      </div>
    );
  }

  render() {
    const {
      year,
      month,
      weekStartsOn,
      locale,
      hideMonthName,
      showWeekNumber,
      onWeekDayClicked,
      renderWeekDay,
      renderMonthName,
      className,
      excludedDaysOfWeek,
    } = this.props;

    const calendar = monthCalendar(year, month, {
      showFullDates: true,
      weekStartsOn,
    }) as (typeof EMPTY_DAY | Date)[][];

    const secondDayOfMonth = calendar[1][0];
    const monthName = hideMonthName
      ? ''
      : new Intl.DateTimeFormat(locale, { month: 'long' }).format(
          secondDayOfMonth,
        );

    const weekNumberMargin =
      weekStartsOn === Weekdays.MONDAY && showWeekNumber ? (
        <div className="CalendarMonth-week-number" />
      ) : null;

    const weekdays = getWeekdays({ weekStartsOn, locale }).map(
      ({ short, narrow }, index) => (
        <div
          className={
            'CalendarMonth-weekday ' +
            (excludedDaysOfWeek!.includes(short)
              ? 'CalendarMonth-weekday--excluded'
              : '')
          }
          key={index}
          onClick={() => onWeekDayClicked!(index)}
        >
          {renderWeekDay!(narrow)}
        </div>
      ),
    );

    const renderedWeeks = calendar.map(this.renderWeek);
    return (
      <div className={`CalendarMonth ${className}`} key={month}>
        {renderMonthName!(monthName, year)}
        <div className="CalendarMonth-weekdays">
          {weekNumberMargin}
          {weekdays}
        </div>
        {renderedWeeks}
      </div>
    );
  }
}
