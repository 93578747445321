import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { TombacTheme } from '../../shared';
import { defaultTheme } from '../../themes/defaultTheme';
import { GothamFont } from '../Fonts/GothamFont';
import { NowayFont } from '../Fonts/NowayFont';
import { CssVariables } from './CssVariables';

export type TombacAppProps = PropsWithChildren<{
  defineCssVariables?: boolean;
  theme?: Partial<TombacTheme>;
}>;

const emptyTheme = {};

export const TombacApp: FC<TombacAppProps> = ({
  children,
  defineCssVariables = false,
  theme = emptyTheme,
}) => {
  const [appTheme, setAppTheme] = useState({ ...defaultTheme, ...theme });

  useEffect(
    function updateAppTheme() {
      setAppTheme({ ...defaultTheme, ...theme });
    },
    [theme],
  );

  return (
    <ThemeProvider theme={appTheme}>
      <GothamFont />
      <NowayFont />
      {defineCssVariables && <CssVariables />}
      {children}
    </ThemeProvider>
  );
};
