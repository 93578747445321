import styled, { css, keyframes } from 'styled-components';
import { tombac } from '../../shared/tombac';
import { TooltipProps } from './Tooltip';

type TooltipStyleProps = Pick<TooltipProps, 'size' | 'variant'>;

const arrowVariants = tombac.variant<TooltipStyleProps>('variant', {
  default: css`
    &::after {
      background-color: ${tombac.color.white};
    }
  `,
  danger: css`
    &::after {
      background-color: ${tombac.color('danger', 400)};
    }
  `,
  inverted: css`
    &::after {
      background-color: ${tombac.color.black};
    }
  `,
});

export const Arrow = styled.div<TooltipStyleProps>`
  all: initial;
  height: ${tombac.space(1)};
  position: relative;
  width: ${tombac.space(1)};

  &::after {
    all: initial;
    content: '';
    display: block;
    position: absolute;
    transform: rotate(45deg);
    height: 100%;
    width: 100%;
  }

  ${arrowVariants}
`;




const tooltipVariants = tombac.variant<TooltipStyleProps>('variant', {
  default: css`
    background-color: ${tombac.color.white};
    color: ${tombac.color.black};
  `,
  danger: css`
    background-color: ${tombac.color('danger', 400)};
    color: ${tombac.color.white};
  `,
  inverted: css`
    background-color: ${tombac.color.black};
    color: ${tombac.color.white};
  `,
});

const tooltipSizes = tombac.variant<TooltipStyleProps>('size', {
  m: css`
    padding: ${tombac.space(2)};
  `,
  s: css`
    padding: ${tombac.space(1)};
  `,
});

const TooltipAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;




export const StyledTooltip = styled.div<TooltipStyleProps>`
  all: initial;
  animation: ${TooltipAnimation} 0.25s ease-in-out;
  border-radius: ${tombac.unit(3)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 999999;
  box-shadow: ${tombac.unit(0, 4, 16, 1)} rgba(0,0,0, 16%);

  &[data-popper-placement^='bottom'] > ${Arrow} {
    left: 0;
    top: 0;
    margin-top: ${tombac.space(-0.4)};
  }

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: 0;
    margin-bottom: ${tombac.space(-0.4)};
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: 0;
    margin-left: ${tombac.space(-0.4)};
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: 0;
    margin-right: ${tombac.space(-0.3)};
  }

  ${tooltipVariants};
  ${tooltipSizes};
`;
