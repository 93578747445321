import {
  ColorAlphaGrade,
  ColorGrade,
  ThemeColor,
  ThemeColorDefs,
  TombacTheme,
} from '../tombac/tombacTypes';
import { pxToUnit } from './tombacCommons';
import { CSSObject } from 'styled-components';

function colorGrades(
  colorDefs: ThemeColorDefs,
  color: ThemeColor,
): ColorGrade[] {
  return Object.keys(colorDefs[color]).filter(
    (key) => !isNaN(parseFloat(key)),
  ) as any;
}

function alphaGrades(
  colorDefs: ThemeColorDefs,
  color: ThemeColor,
  type?: 'inverted',
): ColorAlphaGrade[] {
  return Object.keys(
    colorDefs[color][type === 'inverted' ? 'invertedAlpha' : 'alpha']!,
  ) as any;
}

function gradeVariable(
  colorDefs: ThemeColorDefs,
  color: ThemeColor,
  grade: ColorGrade | ColorAlphaGrade,
  alpha?: 'alpha' | 'inverted',
): Record<string, string> {
  const name = `${color}-${alpha === 'inverted' ? 'i' : ''}${
    alpha ? 'a' : ''
  }${grade}`;
  const value =
    alpha === 'alpha'
      ? colorDefs[color].alpha[grade as ColorAlphaGrade]
      : alpha === 'inverted'
      ? colorDefs[color].invertedAlpha![grade as ColorAlphaGrade]
      : colorDefs[color][grade as ColorGrade];
  return { [name]: value };
}

function colorVariables(theme: TombacTheme) {
  const variables: CSSObject = {};
  (Object.keys(theme.colors) as ThemeColor[]).map((color) => {
    const colorDefs = theme.colors!;

    variables[color] = colorDefs[color][500];

    colorGrades(colorDefs, color).forEach((grade) =>
      Object.assign(variables, gradeVariable(colorDefs, color, grade)),
    );

    alphaGrades(colorDefs, color).forEach((grade) =>
      Object.assign(variables, gradeVariable(colorDefs, color, grade, 'alpha')),
    );

    if (colorDefs[color].invertedAlpha)
      alphaGrades(colorDefs, color, 'inverted').forEach((grade) =>
        Object.assign(
          variables,
          gradeVariable(colorDefs, color, grade, 'inverted'),
        ),
      );

    return variables;
  });
  return variables;
}

function spacingVariables(theme: TombacTheme) {
  const variables: CSSObject = {};
  [0.5, 1, 2, 3, 4, 5].forEach(
    (space) =>
      (variables[`space-${space === 0.5 ? 'half' : space}`] = pxToUnit(
        theme.baseSpace * space,
        theme.baseUnit,
      )),
  );
  return variables;
}

export function createCssVariables(theme: TombacTheme, prefix = '--') {
  const variables = Object.assign(
    {},
    colorVariables(theme),
    spacingVariables(theme),
  );
  if (prefix) {
    Object.keys(variables).forEach((key) => {
      variables[`${prefix}${key}`] = variables[key];
      delete variables[key];
    });
  }
  return variables;
}
