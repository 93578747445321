import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { propStyling, PropsWithPropStyling } from '../../../shared/propStyling';
import { tombac } from '../../../shared/tombac';

export type ModalActionsProps = PropsWithPropStyling<{
  secondary?: ReactNode;
}>;

const Container = styled.div`
  display: flex;
  gap: ${tombac.space(2)};
  margin: ${tombac.space(3)};
  ${propStyling}
`;

const PrimaryActions = styled.div`
  display: flex;
  gap: ${tombac.space(1)};
  margin-left: auto;
  order: 2;
`;

const SecondaryActions = styled.div`
  display: flex;
  gap: ${tombac.space(1)};
  order: 1;
`;

export const ModalActions: FC<ModalActionsProps> = ({
  children,
  secondary,
  ...stylingProps
}) => (
  <Container {...stylingProps}>
    {children && <PrimaryActions>{children}</PrimaryActions>}
    {secondary && <SecondaryActions>{secondary}</SecondaryActions>}
  </Container>
);
