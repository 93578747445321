import styled, { css } from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { TextStyleProps, textStyles } from '../../shared/textStyles';
import { tombac, TypographyProps } from '../../shared/tombac';

export type LabelProps = PropsWithPropStyling<
  TextStyleProps &
    TypographyProps & {
      required?: boolean;
    }
>;

export const Label = styled.label<LabelProps>`
  all: initial;

  ${({ required }) =>
    required
      ? css`
          &::after {
            color: ${tombac.color('danger')};
            content: '*';
            display: inline;
          }
        `
      : undefined}

  ${tombac.typography.label}
  ${textStyles}
  ${propStyling}
`;
