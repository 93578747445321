import cx from 'classnames';
import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { extractStylingProps } from '../../shared/propStyling/propStylingUtils';
import { tombac } from '../../shared/tombac';

export interface InputCoreProps {
  append?: ReactNode;
  highlighted?: boolean;
  invalid?: boolean;
  prepend?: ReactNode;
  variant?: 'default' | 'alert' | 'danger';
}

export type InputProps = PropsWithPropStyling<
  InputHTMLAttributes<HTMLInputElement> & InputCoreProps
>;

const Append = styled.span<
  Pick<InputProps, 'append' | 'disabled' | 'invalid' | 'variant'>
>`
  all: unset;
  align-items: center;
  color: ${({ invalid, variant }) =>
    invalid || variant === 'danger'
      ? tombac.color('danger', 500)
      : variant === 'alert'
      ? tombac.color('alert', 600)
      : 'inherit'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};
  display: flex;
  padding: ${({ append }) => (!append ? 0 : tombac.space(0, 2, 0, 0))};
  padding-top: ${({ append }) =>
    typeof append === 'string'
      ? '0.2em' /* Fixes Noway font alignment */
      : undefined};
`;

const Container = styled.span<PropsWithPropStyling>`
  all: initial;
  display: inline-flex;
  position: relative;
  width: ${tombac.space(20)};

  ${tombac.text({ fontSize: 14 })}
  ${propStyling}
`;

const InputElement = styled.input<InputProps>`
  all: initial;
  box-sizing: border-box;
  height: ${tombac.space(5)};
  min-width: 0;
  padding: ${({ prepend }) => tombac.space(0, 2, 0, prepend ? 6 : 2)};
  padding-top: 0.2em; /* Fixes Noway font alignment */

  ${tombac.text({ fontSize: 14 })}

  ${({ append, prepend }) =>
    append !== undefined || prepend !== undefined
      ? css`
          flex: 1;
          width: 0;
        `
      : css`
          width: ${tombac.space(20)};
        `}

  &,
  & + ${Append} {
    background-color: ${tombac.color.white};
    border: solid
      ${({ highlighted, invalid, variant }) =>
        highlighted
          ? tombac.color('accent', 600)
          : invalid || variant === 'danger'
          ? tombac.color('danger', 500)
          : variant === 'alert'
          ? tombac.color('alert', 500)
          : tombac.color('neutral', 400)};
    border-radius: ${tombac.unit(0, 2, 2, 0)};
    border-width: ${tombac.unit(1, 1, 1, 0)};
    transition: 0.1s ease-out;
    transition-property: border-color, background-color, color;
  }

  & {
    border-radius: ${({ append }) =>
      append ? tombac.unit(2, 0, 0, 2) : tombac.unit(2)};
    border-width: ${({ append }) =>
      append ? tombac.unit(1, 0, 1, 1) : tombac.unit(1)};
  }

  &::placeholder {
    color: ${tombac.color('neutral', 600)};
    transition: color 0.1s ease-out;
    opacity: 1;
  }

  &:invalid,
  &:invalid + ${Append} {
    border-color: ${tombac.color('danger', 500)};
  }

  &:invalid + ${Append} {
    color: ${tombac.color('danger', 500)};
  }

  &:focus,
  &:focus + ${Append} {
    border-color: ${tombac.color('accent', 600)};
  }

  &:hover,
  &:hover + ${Append} {
    background-color: ${tombac.color('neutral', 200)};
  }

  &:disabled,
  &:disabled + ${Append} {
    background-color: ${tombac.color('neutral', 300)};
    color: ${tombac.alpha('neutral', 24)};
  }

  &:disabled::placeholder {
    color: ${tombac.alpha('neutral', 24)};
  }

  ${propStyling}
`;

const Prepend = styled.span<
  Pick<InputProps, 'disabled' | 'invalid' | 'prepend' | 'variant'>
>`
  align-items: center;
  box-sizing: border-box;
  color: ${({ disabled, invalid, variant }) =>
    disabled
      ? tombac.alpha('neutral', 24)
      : invalid || variant === 'danger'
      ? tombac.color('danger', 500)
      : variant === 'alert'
      ? tombac.color('alert', 600)
      : 'inherit'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};
  display: flex;
  height: ${tombac.space(5)};
  justify-content: center;
  left: 0;
  padding-top: ${({ prepend }) =>
    typeof prepend === 'string'
      ? '0.2em' /* Fixes Noway font alignment */
      : undefined};
  position: absolute;
  top: 0;
  width: ${tombac.space(6)};
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...rest }, ref) => {
    const { append, disabled, invalid, prepend, variant } = rest;
    const componentClass = cx('TombacInput', className);
    if (append !== undefined || prepend !== undefined) {
      const [stylingProps, nonStylingProps] = extractStylingProps(rest);
      return (
        <Container className={componentClass} {...stylingProps}>
          {prepend && (
            <Prepend {...{ disabled, invalid, prepend, variant }}>
              {prepend}
            </Prepend>
          )}
          <InputElement {...nonStylingProps} ref={ref} />
          {append && (
            <Append {...{ append, disabled, invalid, variant }}>
              {append}
            </Append>
          )}
        </Container>
      );
    } else {
      return <InputElement className={componentClass} {...rest} ref={ref} />;
    }
  },
);
