import { MouseEventHandler, MouseEvent } from 'react';

export const createRipple = (
  callback: MouseEventHandler<HTMLButtonElement> | undefined,
) => (event: MouseEvent<HTMLButtonElement>): void => {
  const button = event.currentTarget;
  button.setAttribute('data-ripple', 'active');
  setTimeout(
    () =>
      requestAnimationFrame(
        () => button && button.removeAttribute('data-ripple'),
      ),
    550, // Synchronized with animation keyframes
  );

  const circle = document.createElement('span');
  const effect = document.createElement('span');

  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;
  const reacts = button.getClientRects()[0];
  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - reacts.x - radius}px`;
  circle.style.top = `${event.clientY - reacts.y - radius}px`;
  circle.classList.add('TombacButton__ripple');
  const ripple = button.getElementsByClassName('TombacButton__ripple')[0];
  if (ripple) {
    ripple.remove();
  }
  effect.classList.add('TombacButton__ripple--active');
  circle.appendChild(effect);
  button.appendChild(circle);
  if (callback) callback(event);
};
