import styled, { css } from 'styled-components';
import { propStyling, PropsWithPropStyling } from '../../../shared/propStyling';
import { tombac } from '../../../shared/tombac';

export interface ModalLayoutProps extends PropsWithPropStyling {
  size?: 's' | 'm' | 'l';
}

const sizeStyles = tombac.variant<ModalLayoutProps>('size', {
  s: css`
    width: ${tombac.unit(288)};
  `,
  m: css`
    width: ${tombac.unit(496)};
  `,
  l: css`
    width: ${tombac.unit(715)};
  `,
});

export const ModalLayout = styled.div.attrs<ModalLayoutProps>(({ size }) => ({
  size: size ?? 'm',
}))<ModalLayoutProps>`
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  position: relative;
  ${sizeStyles}
  ${propStyling};
`;
