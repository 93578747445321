import { ElementType } from 'react';
import styled from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { TextStyleProps, textStyles } from '../../shared/textStyles';
import { HeadingTypographyProps, tombac } from '../../shared/tombac';

export type HeadingProps = PropsWithPropStyling<
  HeadingTypographyProps &
    TextStyleProps & {
      as?: ElementType;
    }
>;

export const Heading = styled.div.attrs<HeadingProps>(({ as, level }) => ({
  as: as ?? 'h' + level,
}))<HeadingProps>`
  all: initial;
  display: block;

  ${tombac.typography.heading}
  ${textStyles}
  ${propStyling}
`;
