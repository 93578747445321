import { Placement, StrictModifiers } from '@popperjs/core';
import React, {
  FC,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Modifier, usePopper } from 'react-popper';
import { useOutsideClick } from '../../shared/useOutsideClick';
import { Text } from '../Typography';
import { Arrow, StyledTooltip } from './TooltipStyles';

export interface TooltipControlRenderProps {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export interface TooltipChildrenRenderProps extends TooltipControlRenderProps {
  ref: (element: HTMLElement | null) => void;
}

export interface TooltipCommonProps extends HTMLAttributes<HTMLElement> {
  /** Should return reference element of the tooltip. */
  children: (
    props: TooltipChildrenRenderProps & TooltipControlRenderProps,
  ) => ReactNode;
  /** Popper.js modifiers. Read more: https://popper.js.org/docs/v2/modifiers/ */
  modifiers?: ReadonlyArray<StrictModifiers>;
  /** Set the offset of the tooltip (default = [0, 12]). */
  offset?: [number, number];
  /** Placement for the tooltip. Read more: https://popper.js.org/popper-documentation.html#Popper.placements  */
  placement?: Placement;
  /** Determines the size of the padding (default = 'm'). */
  size?: 's' | 'm';
  /** Whether to portal the tooltip to the end of the document body (default = false). */
  usePortal?: boolean;
  /** Color variant. 'default' is white, 'inverted' black and 'danger' red (default = 'default'). */
  variant?: 'default' | 'inverted' | 'danger';
}

type TooltipUncontrolledProps = {
  /** Whether to close the tooltip when clicked outside (default = true). */
  closeOnOutsideClick?: boolean;
  /** Provides the content of the tooltip. */
  content:
    | string
    | ReactElement
    | ((props: TooltipControlRenderProps) => ReactNode);
  isOpen?: never;
  /** A callback function called when the tooltip closes. */
  onClose?: () => void;
  /** A callback function called when the tooltip opens. */
  onOpen?: () => void;
};

type TooltipControlledProps = {
  closeOnOutsideClick?: never;
  /** Provides the content of the tooltip. */
  content: ReactNode;
  /** Switches the component to controlled mode. */
  isOpen: boolean;
  onClose?: never;
  onOpen?: never;
};

export type TooltipProps = TooltipCommonProps &
  (TooltipUncontrolledProps | TooltipControlledProps);

export const Tooltip: FC<TooltipProps> = ({
  children,
  closeOnOutsideClick = true,
  content,
  isOpen: isOpenProp,
  modifiers = [],
  offset = [0, 12],
  onClose,
  onOpen,
  placement = 'auto',
  size = 'm',
  usePortal = false,
  variant = 'default',
  ...rest
}) => {
  const [tooltipEl, setTooltipEl] = useState<HTMLElement | null>(null);
  const [triggerEl, setTriggerEl] = useState<HTMLElement | null>(null);
  const [arrowEl, setArrowEl] = useState<HTMLDivElement | null>(null);
  const [isOpenState, setIsOpen] = useState(false);

  const isControlled = typeof isOpenProp === 'boolean';
  const isOpen = isControlled ? isOpenProp : isOpenState;

  useOutsideClick(tooltipEl, () => setIsOpen(false), {
    enabled: !isControlled && closeOnOutsideClick,
    excluded: [triggerEl],
  });

  const arrowModifier: Modifier<any> = {
    name: 'arrow',
    enabled: true,
    options: {
      element: arrowEl,
      padding: 8,
    },
  };

  const offsetModifier: Modifier<any> = {
    name: 'offset',
    enabled: true,
    options: { offset },
  };

  const { styles, attributes, update } = usePopper(triggerEl, tooltipEl, {
    placement,
    modifiers: [
      offsetModifier,
      arrowModifier,
      { name: 'computeStyles', options: { adaptive: false } },
      ...modifiers,
    ],
  });

  useEffect(() => {
    if (isControlled) return;
    if (isOpenState && onOpen) {
      onOpen();
    } else if (!isOpenState && onClose) {
      onClose();
    }
  }, [isControlled, isOpenState]);

  // Workaround for initial offset of the tooltip.
  useEffect(() => {
    if (!update || !isOpen) return;
    const timeout = setTimeout(update, 50);
    return () => clearTimeout(timeout);
  }, [isOpen, update]);

  const controlFns = {
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen(prev => !prev),
  };

  const renderPopper = (popper: ReactNode) =>
    usePortal ? createPortal(popper, window.document.body) : popper;

  return (
    <>
      {children({
        ...controlFns,
        ref: setTriggerEl,
      })}
      {isOpen &&
        renderPopper(
          <StyledTooltip
            ref={setTooltipEl}
            style={styles.popper}
            {...{ variant, size }}
            {...attributes.popper}
            {...rest}
          >
            {typeof content === 'string' ? (
              // Padding top applied to compensate Noway font offset.
              <Text $color="inherit" $pt="0.2em">
                {content}
              </Text>
            ) : typeof content === 'function' ? (
              content(controlFns)
            ) : (
              content
            )}
            <Arrow ref={setArrowEl} style={styles.arrow} variant={variant} />
          </StyledTooltip>,
        )}
    </>
  );
};
