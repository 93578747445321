import { css } from 'styled-components';
import {
  backgroundCssAttrNames,
  BackgroundPropStylingProps,
  backgroundPropStyling,
} from './categories/backgroundPropStyling';
import {
  borderCssAttrNames,
  BorderPropStylingProps,
  borderPropStyling,
} from './categories/borderPropStyling';
import {
  flexboxCssAttrAliases,
  flexboxCssAttrNames,
  FlexboxPropStylingProps,
  flexboxPropStyling,
} from './categories/flexboxPropStyling';
import {
  gridCssAttrNames,
  GridPropStylingProps,
  gridPropStyling,
} from './categories/gridPropStyling';
import {
  layoutCssAttrAliases,
  layoutCssAttrNames,
  LayoutPropStylingProps,
  layoutPropStyling,
} from './categories/layoutPropStyling';
import {
  positionCssAttrNames,
  PositionPropStylingProps,
  positionPropStyling,
} from './categories/positionPropStyling';
import {
  shadowCssAttrNames,
  ShadowPropStylingProps,
  shadowPropStyling,
} from './categories/shadowPropStyling';
import {
  spaceCssAttrAliases,
  spaceCssAttrNames,
  SpacePropStylingProps,
  spacePropStyling,
} from './categories/spacePropStyling';

export const propStylingCssAttrNames = [
  ...backgroundCssAttrNames,
  ...borderCssAttrNames,
  ...flexboxCssAttrNames,
  ...gridCssAttrNames,
  ...layoutCssAttrNames,
  ...positionCssAttrNames,
  ...shadowCssAttrNames,
  ...spaceCssAttrNames,
];

export const propStylingCssAttrAliases = {
  ...flexboxCssAttrAliases,
  ...layoutCssAttrAliases,
  ...spaceCssAttrAliases,
};

export type PropsWithPropStyling<P = {}> = P &
  BackgroundPropStylingProps &
  BorderPropStylingProps &
  FlexboxPropStylingProps &
  GridPropStylingProps &
  LayoutPropStylingProps &
  PositionPropStylingProps &
  ShadowPropStylingProps &
  SpacePropStylingProps;

export const propStyling = css`
  ${backgroundPropStyling}
  ${borderPropStyling}
  ${flexboxPropStyling}
  ${gridPropStyling}
  ${layoutPropStyling}
  ${positionPropStyling}
  ${shadowPropStyling}
  ${spacePropStyling}
`;
