import styled, { StyledComponent } from 'styled-components';
import { deprecated__LayoutStyleProps } from '../../../shared/deprecated/layoutStyles';
import { Box, BoxProps } from '../Box';

export type BoxPropsCompat = {
  [key in
    | keyof BoxProps
    | keyof deprecated__LayoutStyleProps]?: key extends keyof deprecated__LayoutStyleProps
    ? deprecated__LayoutStyleProps[key]
    : key extends keyof BoxProps
    ? BoxProps[key]
    : never;
};

const BoxCompat = Box as StyledComponent<'div', any, BoxPropsCompat, never>;

export const HFlex = styled(BoxCompat).attrs<BoxPropsCompat>(() => ({
  display: 'flex',
  flexDirection: 'row',
}))``;

export const HGrid = styled(BoxCompat).attrs<BoxPropsCompat>(
  ({ gap, gridAutoColumns, jc, justifyContent }) => {
    return {
      display: 'grid',
      gap: gap ?? 2,
      gridAutoColumns: gridAutoColumns ?? 'max-content',
      gridAutoFlow: 'column',
      justifyContent: justifyContent ?? jc ?? 'start',
    };
  },
)``;

export const VFlex = styled(BoxCompat).attrs<BoxPropsCompat>(() => ({
  display: 'flex',
  flexDirection: 'column',
}))``;

export const VGrid = styled(BoxCompat).attrs<BoxPropsCompat>(
  ({ gap, gridAutoRows, jc, justifyContent }) => ({
    display: 'grid',
    gap: gap ?? 2,
    gridAutoFlow: 'row',
    gridAutoRows: gridAutoRows ?? 'max-content',
    justifyContent: justifyContent ?? jc ?? 'start',
  }),
)``;
