import { Menu } from './../components/Menu/Menu';
import { useEffect } from 'react';

export interface useOutsideClickOptions {
  /* If false, outside clicks will be ignored (default = true). */
  enabled?: boolean;
  /* Events to listen to (default = ['mousedown', 'touchstart']).  */
  events?: (keyof DocumentEventMap)[];
  excluded?: (Element | null | undefined)[]
}

/**
 * Handle clicks outside of an element.
 *
 * @param element Element, which clicks should be ignored.
 * @param onOutsideClick Function to fire on outside click.
 * @param options Additional options.
 */
export function useOutsideClick(
  element: Element | null,
  onOutsideClick: (event: Event) => any,
  {
    enabled = true,
    events = ['mousedown', 'touchstart'],
    excluded
  }: useOutsideClickOptions,
) {
  useEffect(() => {
    if (!element || !enabled ) return;

    const handleEvent = (event: Event) => {
      if(excluded?.length && excluded.find(element => element?.contains(event.target as Element))) return;
      if (!element.contains(event.target as Element)) {
        onOutsideClick(event);
      }
    };

    for (let eventName of events) {
      document.addEventListener(eventName, handleEvent);
    }

    return () => {
      for (let eventName of events) {
        document.removeEventListener(eventName, handleEvent);
      }
    };
  }, [element, enabled, events, onOutsideClick]);
}
