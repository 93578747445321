import { createPropStyling, PropStylingProps } from '../privatePropStylingUtils';

export const positionCssAttrNames = [
  'bottom',
  'left',
  'position',
  'right',
  'top',
  'zIndex',
] as const;

export type PositionCssAttrName = typeof positionCssAttrNames[number];

export type PositionPropStylingProps = PropStylingProps<PositionCssAttrName>;

export const positionPropStyling = createPropStyling<PositionPropStylingProps>(
  positionCssAttrNames,
);
