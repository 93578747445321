import cx from 'classnames';
import React, {
  ChangeEventHandler,
  FC,
  Fragment,
  HTMLAttributes,
  ReactNode,
  ReactText,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { tombac } from '../../shared/tombac';

interface DefaultOptionType {
  label: string;
  value: string;
}

export interface RadioGroupOptionLabelGetter<OptionType> {
  (option: OptionType): ReactNode;
}

export interface RadioGroupOptionValueGetter<OptionType> {
  (option: OptionType): ReactText;
}

export interface RadioGroupChangeHandler<OptionType> {
  (option: OptionType | null): any;
}

export interface RadioGroupCoreProps<OptionType = unknown> {
  disabled?: boolean;
  getOptionLabel?: RadioGroupOptionLabelGetter<OptionType>;
  getOptionValue?: RadioGroupOptionValueGetter<OptionType>;
  name?: string;
  onChange?: RadioGroupChangeHandler<OptionType>;
  options: OptionType[];
  value?: OptionType | null;
  variant: 'horizontal';
}

export type RadioGroupProps<OptionType = unknown> = PropsWithPropStyling<
  Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
    RadioGroupCoreProps<OptionType>
>;

type ContainerProps = PropsWithPropStyling<
  Pick<RadioGroupCoreProps, 'disabled' | 'variant'>
>;

const Container = styled.div<ContainerProps>`
  all: initial;

  ${({ disabled }) =>
    tombac.text({ color: disabled ? tombac.alpha('neutral', 24) : undefined })}

  ${tombac.variant<ContainerProps>('variant', {
    horizontal: css<ContainerProps>`
      background-color: ${tombac.color.white};
      border: ${tombac.unit(1)} solid ${tombac.color('neutral', 400)};
      border-radius: ${tombac.unit(1)};
      box-sizing: border-box;
      display: inline-flex;
      height: ${tombac.space(5)};
      margin: 0;
      padding: ${tombac.space(0.5)};
      transition: border-color 0.1s ease-out;

      &:hover,
      &:focus-within {
        border-color: ${({ disabled }) =>
          disabled ? undefined : tombac.color('accent', 600)};
      }
    `,
  })}
  ${propStyling}
`;

type InputProps = Pick<RadioGroupProps, 'variant'>;

const Input = styled.input<InputProps>`
  all: unset;
  ${tombac.variant<InputProps>('variant', {
    horizontal: css`
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    `,
  })}
`;

type LabelProps = Pick<RadioGroupProps, 'disabled' | 'variant'>;

const Label = styled.label<LabelProps>`
  all: initial;
  color: inherit;
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  margin: 0 2px;

  ${tombac.variant<LabelProps>('variant', {
    horizontal: css`
      display: block;
      flex: 1;
    `,
  })}
`;

const LabelText = styled.span<LabelProps>`
  ${tombac.variant<LabelProps>('variant', {
    horizontal: css<LabelProps>`
      align-items: center;
      border-radius: ${tombac.unit(1)};
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: ${tombac.space(0, 2)};
      padding-top: 0.2em; /* Fix Noway font alignment */
      transition: background-color 0.1s ease-out;
      white-space: nowrap;

      &:hover {
        background-color: ${({ disabled }) =>
          disabled ? undefined : tombac.color('neutral', 300)};
      }

      ${Input}:not(:disabled):active + & {
        background-color: ${tombac.color('neutral', 500)};
        color: ${tombac.color.black};
      }

      ${Input}:checked + & {
        background-color: ${tombac.color('accent', 200)};
        color: ${tombac.color('accent', 600)};
      }

      ${Input}:checked:disabled + & {
        background-color: ${tombac.color('neutral', 500)};
        color: ${tombac.alpha('neutral', 32)};
      }
    `,
  })}
`;

const defaultGetOptionLabel: RadioGroupOptionLabelGetter<DefaultOptionType> = (
  option,
) => option.label;

const defaultGetOptionValue: RadioGroupOptionValueGetter<DefaultOptionType> = (
  option,
) => option.value;

function generateId() {
  if (typeof crypto === 'undefined') return null;
  const arr = new Uint8Array(5);
  crypto.getRandomValues(arr);
  return Array.from(arr, (d) => d.toString(16)).join('');
}

interface RadioGroup<OptionType = DefaultOptionType>
  extends FC<RadioGroupProps<OptionType>> {}

export const RadioGroup = <OptionType extends unknown = DefaultOptionType>({
  className,
  disabled = false,
  getOptionLabel = defaultGetOptionLabel as any,
  getOptionValue = defaultGetOptionValue as any,
  name,
  onChange,
  options,
  value,
  variant,
  ...rest
}: RadioGroupProps<OptionType>) => {
  const [id] = useState(generateId());
  const [selected, setSelected] = useState<OptionType | null>(null);
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const selected =
        options.find(
          (option) => event.target.value === getOptionValue(option).toString(),
        ) ?? null;
      if (value === undefined) setSelected(selected);
      if (onChange) onChange(selected);
    },
    [getOptionValue, onChange, options],
  );
  useEffect(
    function handleValuePropChange() {
      setSelected(value ?? null);
    },
    [value],
  );
  return id ? (
    <Container
      className={cx('TombacRadioGroup', className)}
      disabled={disabled}
      variant={variant}
      {...rest}
    >
      {options.map((option) => {
        const label = getOptionLabel(option);
        const value = getOptionValue(option);
        return (
          <Fragment key={value}>
            <Label variant={variant}>
              <Input
                checked={option === selected}
                disabled={disabled}
                name={name ?? `radio-${id}`}
                onChange={handleChange}
                type="radio"
                value={value}
                variant={variant}
              />{' '}
              <LabelText disabled={disabled} variant={variant}>
                {label}
              </LabelText>
            </Label>
            <br />
          </Fragment>
        );
      })}
    </Container>
  ) : null;
};
