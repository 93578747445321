import { createPropStyling, PropStylingProps } from '../privatePropStylingUtils';

export const spaceCssAttrNames = [
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
] as const;

export const spaceCssAttrAliases = {
  m: 'margin',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mr: 'marginRight',
  mt: 'marginTop',
  p: 'padding',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  pr: 'paddingRight',
  pt: 'paddingTop',
} as const;

export type SpaceCssAttrName = typeof spaceCssAttrNames[number];
export type SpaceCssAttrAlias = keyof typeof spaceCssAttrAliases;

export type SpacePropStylingProps = PropStylingProps<
  SpaceCssAttrName,
  SpaceCssAttrAlias
>;

export const spacePropStyling = createPropStyling<SpacePropStylingProps>(
  spaceCssAttrNames,
  spaceCssAttrAliases,
);
