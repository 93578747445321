import { CSSObject } from 'styled-components';
import { pxToUnit, resolveTheme } from '../cjs/tombacCommons';
import { PropsWithTheme, TombacTheme } from './tombacTypes';

export interface FontAttributes {
  color?: string | ((props: { theme: Partial<TombacTheme> }) => string);
  fontFamily?: string | ((props: { theme: Partial<TombacTheme> }) => string);
  fontSize?: number;
  fontWeight?: string | number;
  lineHeight?: string | number;
  withDefaults?: boolean;
}

export const altFontFamily = ({ theme }: PropsWithTheme) =>
  resolveTheme(theme).typography.altFontFamily;

export const altText = (attributes: FontAttributes = {}) =>
  text({ ...attributes, fontFamily: altFontFamily });

export const fontFamily = ({ theme }: PropsWithTheme) =>
  resolveTheme(theme).typography.defaultFontFamily;

export const text = ({
  color,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  withDefaults = true,
}: FontAttributes = {}) => ({ theme }: PropsWithTheme) => {
  const {
    baseUnit,
    colors,
    typography: {
      defaultColor,
      defaultFontSize,
      defaultFontWeight,
      defaultLineHeight,
      defaultFontFamily,
    },
  } = resolveTheme(theme);
  const styles = {
    color:
      (typeof color === 'function' ? color({ theme }) : color) ??
      (withDefaults ? colors[defaultColor[0]][defaultColor[1]] : undefined),
    fontFamily:
      (typeof fontFamily === 'function' ? fontFamily({ theme }) : fontFamily) ??
      (withDefaults ? defaultFontFamily : undefined),
    fontSize:
      withDefaults || fontSize !== undefined
        ? pxToUnit(fontSize ?? defaultFontSize, baseUnit)
        : undefined,
    fontWeight: fontWeight ?? (withDefaults ? defaultFontWeight : undefined),
    lineHeight: lineHeight ?? (withDefaults ? defaultLineHeight : undefined),
  } as CSSObject;

  if (withDefaults) {
    Object.assign(styles, {
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    });
  }

  return styles;
};
