import detectOverflow from '@popperjs/core/lib/utils/detectOverflow';
import React, {
  FC,
  HTMLAttributes,
  SVGProps,
  useEffect,
  useState,
} from 'react';
import { Modifier, usePopper } from 'react-popper';
import styled from 'styled-components';
import { tombac } from '../../shared/tombac';
import { Button } from '../Button/Button';

export interface NavbarCollapseProps extends HTMLAttributes<HTMLElement> {
  breakpoint?: number;
}

const Children = styled.div<Required<Pick<NavbarCollapseProps, 'breakpoint'>>>`
  align-items: center;
  align-self: stretch;
  background: ${tombac.color.white};
  display: flex;
  flex: auto;

  @media (max-width: ${({ breakpoint }) => tombac.unit(breakpoint)}) {
    box-shadow: 0 ${tombac.unit(2, 8)} 0 hsla(0, 0%, 0%, 0.04);
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 100%;
    transition: max-height 0.1s ease-out;
  }
`;

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex: auto;
`;

const HamburgerIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="4 -4 16 24" height="24" width="24" {...props}>
    <path d="M4 14h16v-2H4zm0-5h16V7H4zm0-5h16V2H4z" fill="currentColor" />
  </svg>
);

const ExpandButton = styled(Button)<
  Required<Pick<NavbarCollapseProps, 'breakpoint'>>
>`
  align-self: center;
  display: none;
  margin-left: auto;
  margin-right: ${tombac.space(2)};

  @media (max-width: ${({ breakpoint }) => tombac.unit(breakpoint)}) {
    display: block;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  padding-bottom: ${tombac.unit(8)};
  position: relative;
  visibility: hidden;
  width: ${({ isOpen }) => (isOpen ? 'auto' : '0 !important')};

  &::after {
    box-shadow: inset 0 ${tombac.unit(10, 8, -8)} hsla(0, 0%, 0%, 0.04);
    content: '';
    height: ${tombac.unit(8)};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    position: absolute;
    top: 0;
    transition: opacity 0.1s ease-out;
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    width: 100%;
  }
`;

const MenuInner = styled.div<
  Required<Pick<NavbarCollapseProps, 'breakpoint'>> & { isOpen: boolean }
>`
  background-color: ${tombac.color.white};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: translate(0, -100%);
  transition: 0.1s ease-out;
  transition-property: opacity, transform;
  visibility: visible;

  &::after {
    bottom: ${tombac.unit(-8)};
    box-shadow: inset 0 ${tombac.unit(10, 8, -8)} hsla(0, 0%, 0%, 0.04);
    content: '';
    height: ${tombac.unit(8)};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    position: absolute;
    transition: opacity 0.1s ease-out;
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    width: 100%;
  }

  @media (max-width: ${({ breakpoint }) => tombac.unit(breakpoint)}) {
    transform: translate(0, ${({ isOpen }) => (isOpen ? '0' : '-100%')});
  }
`;

const maxWidth: Modifier<'maxWidth'> = {
  name: 'maxWidth',
  enabled: true,
  phase: 'main',
  fn: ({ state, name, options }) => {
    const { width } = state.rects.popper;
    const overflow = detectOverflow(state, options);
    state.modifiersData[name] = width - overflow.left - overflow.right;
  },
};

const applyMaxWidth: Modifier<'applyMaxWidth'> = {
  name: 'applyMaxWidth',
  enabled: true,
  phase: 'beforeWrite',
  fn: ({ state }) => {
    state.styles.popper.width = `${state.modifiersData.maxWidth}px`;
  },
};

const zeroXOffset: Modifier<'zeroXOffset'> = {
  name: 'zeroXOffset',
  enabled: true,
  phase: 'read',
  requires: ['popperOffsets'],
  fn: ({ state }) => {
    if (state.modifiersData.popperOffsets)
      state.modifiersData.popperOffsets.x = 0;
  },
};

export const NavbarCollapse: FC<NavbarCollapseProps> = ({
  breakpoint = 768,
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { attributes, forceUpdate, styles } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        { name: 'offset', enabled: false },
        { name: 'preventOverflow', enabled: false },
        { name: 'arrow', enabled: false },
        { name: 'flip', enabled: false },
        { name: 'hide', enabled: false },
        { name: 'computeStyles', options: { adaptive: false } },
        zeroXOffset,
        maxWidth,
        applyMaxWidth,
      ],
      placement: 'bottom-start',
    },
  );
  useEffect(
    function handleIsOpenChange() {
      if (forceUpdate) forceUpdate();
    },
    [forceUpdate, isOpen],
  );
  useEffect(function handleClosingClick() {
    function handleClick(event: MouseEvent) {
      if (
        (referenceElement &&
          !referenceElement.contains(event.target as Element)) ||
        (popperElement && popperElement.contains(event.target as Element))
      )
        setIsOpen(false);
    }
    addEventListener('click', handleClick);
    return () => removeEventListener('click', handleClick);
  });
  return (
    <Container {...rest} ref={setReferenceElement}>
      <Children breakpoint={breakpoint}>{children}</Children>
      <ExpandButton
        breakpoint={breakpoint}
        onClick={() => setIsOpen(isOpen => !isOpen)}
        square
        variant="flat"
      >
        <HamburgerIcon />
      </ExpandButton>
      <Menu
        isOpen={isOpen}
        ref={setPopperElement}
        style={styles.popper}
        {...{ ...attributes.popper }}
      >
        <MenuInner breakpoint={breakpoint} isOpen={isOpen}>
          {children}
        </MenuInner>
      </Menu>
    </Container>
  );
};
