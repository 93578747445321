import styled from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { TextStyleProps, textStyles } from '../../shared/textStyles';
import { tombac } from '../../shared/tombac';

export interface NavbarMenuProps {
  breakpoint?: number;
}

export const NavbarMenuItem = styled.li<PropsWithPropStyling>`
  all: initial;
  align-items: center;
  display: flex;
  ${propStyling}
`;

export const NavbarMenuLink = styled.a<TextStyleProps>`
  all: initial;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 0 ${tombac.space(2)};
  position: relative;
  ${tombac.altText({ fontWeight: 'bold' })};
  ${textStyles}

  &::after {
    background-color: transparent;
    bottom: 0;
    content: '';
    height: 0;
    left: ${tombac.space(2)};
    margin-top: auto;
    position: absolute;
    transition: 0.1s ease-out;
    transition-property: background-color, height;
    width: calc(100% - ${tombac.space(4)});
  }

  &.active::after,
  &:hover::after {
    background-color: ${tombac.color('primary', 500)};
    height: ${tombac.space(0.5)};
  }
`;

export const NavbarMenu = styled.ul<NavbarMenuProps>`
  all: initial;
  align-items: stretch;
  align-self: stretch;
  display: flex;
  padding: 0 ${tombac.space(1)};

  @media (max-width: ${({ breakpoint = 768 }) => tombac.unit(breakpoint)}) {
    flex-flow: column;
    padding: ${tombac.space(1)} 0;

    &:not(:last-child) {
      border-bottom: ${tombac.unit(1)} solid ${tombac.color('neutral', 400)};
    }

    ${NavbarMenuItem} {
      height: ${tombac.space(7)};
    }

    ${NavbarMenuLink} {
      width: 100%;
    }

    ${NavbarMenuLink}::after {
      display: none;
    }

    ${NavbarMenuLink}::before {
      background-color: transparent;
      content: '';
      height: 100%;
      left: 0;
      margin-top: auto;
      position: absolute;
      top: 0;
      transition: 0.1s ease-out;
      transition-property: background-color, width;
      width: 0;
    }

    ${NavbarMenuLink}.active::before,
    ${NavbarMenuLink}:hover::before {
      background-color: ${tombac.color('danger', 500)};
      width: ${tombac.space(0.5)};
    }
  }
`;
