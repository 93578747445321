import { createContext } from 'react';
import { ButtonSize } from './Button';

export interface ButtonGroupContextType {
  size?: ButtonSize;
}

export const ButtonGroupContext = createContext<ButtonGroupContextType>({
  size: undefined,
});
