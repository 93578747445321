import React, { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';
import styled from 'styled-components';
import { tombac } from '../../shared/tombac';
import { TomTomLogo } from '../Symbols/TomTomLogo';

export type NavbarLogoProps<T extends ElementType> = {
  as?: T;
  subtitle?: ReactNode;
  title?: ReactNode;
} & (T extends ElementType ? ComponentPropsWithoutRef<T> : never);

const Container = styled.div`
  all: initial;
  align-self: stretch;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${tombac.space(0, 3)};
  ${tombac.text({
    color: tombac.color('neutral', 600),
    fontSize: 12,
    fontWeight: 'bold',
  })}

  a&,
  button& {
    cursor: pointer;
  }
`;

const Logo = styled(TomTomLogo)`
  height: ${tombac.space(2)};
  vertical-align: -12%;
`;

const Title = styled.span`
  all: initial;
  text-transform: uppercase;
  ${tombac.altText({ fontSize: 16, fontWeight: 'bold' })}

  a &,
  button & {
    cursor: pointer;
  }
`;

export function NavbarLogo<T extends ElementType = 'div'>({
  subtitle,
  title,
  ...rest
}: NavbarLogoProps<T>) {
  return (
    <Container {...(rest as any)}>
      <div>
        <Logo />
        {title && (
          <> {typeof title === 'string' ? <Title>{title}</Title> : title} </>
        )}
      </div>
      {subtitle}
    </Container>
  );
}
