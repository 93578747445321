import { createPropStyling, PropStylingProps } from '../privatePropStylingUtils';

export const gridCssAttrNames = [
  'gap',
  'gridArea',
  'gridAutoColumns',
  'gridAutoFlow',
  'gridAutoRows',
  'gridColumn',
  'gridColumnGap',
  'gridGap',
  'gridRow',
  'gridRowGap',
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
] as const;

export type GridCssAttrName = typeof gridCssAttrNames[number];

export type GridPropStylingProps = PropStylingProps<GridCssAttrName>;

export const gridPropStyling = createPropStyling<GridPropStylingProps>(gridCssAttrNames);
