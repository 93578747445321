import { ElementType } from 'react';
import styled from 'styled-components';
import { TextStyleProps, textStyles } from '../../shared/textStyles';
import { tombac, TypographyProps } from '../../shared/tombac';
import {
  PropsWithPropStyling,
  propStyling,
} from '../../shared/propStyling/propStyling';

export type TextProps = PropsWithPropStyling<
  TextStyleProps &
    TypographyProps & {
      as?: ElementType;
    }
>;

export const InlineText = styled.span<TextProps>`
  all: initial;

  ${tombac.typography.text}
  ${propStyling}
`;

export const Text = styled.p<TextProps>`
  all: initial;
  display: block;

  ${tombac.typography.text}

  && {
    ${textStyles}
    ${propStyling}
  }
`;
