import React from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as ReactSelectProps,
} from 'react-select';
import styled from 'styled-components';
import { PropsWithPropStyling } from '../../shared/propStyling';
import { extractStylingProps } from '../../shared/propStyling/propStylingUtils';
import { useTombac } from '../../shared/tombac';
import { reactSelectStyles } from './Select/reactSelectStyles';
import { reactSelectTheme } from './Select/reactSelectTheme';
import { selectCss } from './Select/selectCss';
import { TombacSelectProps } from './Select/TombacSelectProps';

export type SelectProps<OptionType> = PropsWithPropStyling<
  ReactSelectProps<OptionType> & TombacSelectProps
>;

const UnstyledSelect = <
  OptionType extends OptionTypeBase = { label: string; value: string }
>(
  props: SelectProps<OptionType>,
) => {
  const tombac = useTombac();
  const [_, nonStylingProps] = extractStylingProps(props);
  return (
    <ReactSelect
      isSearchable={false}
      placeholder=""
      theme={reactSelectTheme(tombac)}
      styles={reactSelectStyles(tombac)}
      {...nonStylingProps}
    />
  );
};

export const Select = styled(UnstyledSelect).attrs(() => ({
  className: 'TombacSelect',
}))`
  ${selectCss}
` as typeof UnstyledSelect;
