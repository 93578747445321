import styled from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { TextStyleProps, textStyles } from '../../shared/textStyles';
import { tombac, TypographyProps } from '../../shared/tombac';

export type CaptionProps = PropsWithPropStyling<TextStyleProps & TypographyProps>;

export const Caption = styled.span<CaptionProps>`
  all: initial;

  ${tombac.typography.caption}
  ${textStyles}
  ${propStyling}
`;
