import { createPropStyling, PropStylingProps } from '../privatePropStylingUtils';

export const flexboxCssAttrNames = [
  'alignContent',
  'alignItems',
  'alignSelf',
  'flex',
  'flexBasis',
  'flexDirection',
  'flexGrow',
  'flexWrap',
  'flexShrink',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'order',
] as const;

export const flexboxCssAttrAliases = {
  jc: 'justifyContent',
} as const;

export type FlexboxCssAttrName = typeof flexboxCssAttrNames[number];
export type FlexboxCssAttrAlias = keyof typeof flexboxCssAttrAliases;

export type FlexboxPropStylingProps = PropStylingProps<
  FlexboxCssAttrName,
  FlexboxCssAttrAlias
>;

export const flexboxPropStyling = createPropStyling<FlexboxPropStylingProps>(
  flexboxCssAttrNames,
  flexboxCssAttrAliases,
);
