import { createPropStyling, PropStylingProps } from '../privatePropStylingUtils';

export const layoutCssAttrNames = [
  'boxSizing',
  'display',
  'height',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'overflow',
  'overflowX',
  'overflowY',
  'verticalAlign',
  'width',
] as const;

export const layoutCssAttrAliases = {
  h: 'height',
  w: 'width',
} as const;

export type LayoutCssAttrName = typeof layoutCssAttrNames[number];
export type LayoutCssAttrAlias = keyof typeof layoutCssAttrAliases;

export type LayoutPropStylingProps = PropStylingProps<
  LayoutCssAttrName,
  LayoutCssAttrAlias
>;

export const layoutPropStyling = createPropStyling<LayoutPropStylingProps>(
  layoutCssAttrNames,
  layoutCssAttrAliases,
);
