import React, { FC, ReactElement } from 'react';
import { Tooltip, TooltipCommonProps } from './Tooltip';

export type TooltipOnHoverProps = Omit<TooltipCommonProps, 'children'> & {
  content: string | ReactElement;
};

export const TooltipOnHover: FC<TooltipOnHoverProps> = ({
  children,
  ...tooltipProps
}) => (
  <Tooltip closeOnOutsideClick={false} {...tooltipProps}>
    {({ ref, open, close }) => (
      <span ref={ref} onMouseEnter={open} onMouseLeave={close}>
        {children}
      </span>
    )}
  </Tooltip>
);
