import styled, { css } from 'styled-components';
import { propStyling, PropsWithPropStyling } from '../../../shared/propStyling';
import { tombac } from '../../../shared/tombac';

export interface ModalContentProps extends PropsWithPropStyling {
  variant?: 'default' | 'scrollable';
}

const variantStyles = tombac.variant<ModalContentProps>('variant', {
  default: css`
    margin: ${tombac.space(2, 3, 0)};

    &:last-child {
      margin-bottom: ${tombac.space(2)};
    }
  `,
  scrollable: css`
    margin: ${tombac.space(3, 0, 0)};
    padding: ${tombac.space(2, 3)};
    border: solid ${tombac.color('neutral', 400)};
    border-width: ${tombac.unit(1, 0)};
    overflow-y: auto;

    &:last-child {
      margin-bottom: ${tombac.space(3)};
    }
  `,
});

export const ModalContent = styled.div.attrs<ModalContentProps>(
  ({ children, variant = 'default' }) => ({ children, variant }),
)<ModalContentProps>`
  ${tombac.text()}
  ${variantStyles}
  ${propStyling}
`;
