import { Weekdays } from "../Weekdays";

const DEFAULT_OPTIONS = {
  weekStartsOn: Weekdays.MONDAY,
  locale: "en-GB"
};

interface Options {
  weekStartsOn?: Weekdays;
  locale?: string;
}

export default function getWeekdays(
  options?: Options
): Array<{ narrow: string; short: string }> {
  const mergedOptions = {
    ...DEFAULT_OPTIONS,
    ...options
  };

  const weekStartsOn =
    typeof mergedOptions.weekStartsOn === "string"
      ? parseInt(mergedOptions.weekStartsOn, 10)
      : mergedOptions.weekStartsOn;

  const range = [...Array(7).keys()];
  return range.map(day => {
    const shiftedToSundayOrMonday = day + 4 + weekStartsOn;
    const date = new Date(1970, 0, shiftedToSundayOrMonday);
    const narrow = date.toLocaleString(mergedOptions.locale, {
      weekday: "narrow"
    });
    const short = date
      .toLocaleDateString(mergedOptions.locale, {
        weekday: "short"
      })
      .toUpperCase();

    return { narrow, short };
  });
}
