import { FontAttributes } from './tombac/textTools';
import { css } from 'styled-components';
import { tombac } from './tombac';

export type TextStyleProps = Omit<FontAttributes, 'color'> & {
  $color?: FontAttributes['color'];
  altFont?: boolean;
};

export const textStyles = css<TextStyleProps>`
  ${({ $color, altFont, ...props }) =>
    altFont
      ? tombac.altText({ ...props, color: $color, withDefaults: false })
      : tombac.text({ ...props, color: $color, withDefaults: false })};
`;
