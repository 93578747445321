import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { CancelIcon } from 'tombac-icons';
import { tombac } from '../../../shared/tombac';
import { Button, ButtonProps } from '../../Button';

export type ModalCloseButtonProps = ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

const Icon = styled(CancelIcon)`
  height: ${tombac.unit(24)};
  width: ${tombac.unit(24)};
`;

export const ModalCloseButton: FC<ModalCloseButtonProps> = (props) => (
  <Button
    $position="absolute"
    $right="1sp"
    $top="1sp"
    variant="flat"
    shape="circle"
    size="xs"
    {...props}
  >
    <Icon />
  </Button>
);
