export function getYearsBetweenDates(startDate: Date, endDate: Date) {
  const startDateYear = startDate.getUTCFullYear();
  const endDateYear = endDate.getUTCFullYear();

  const difference = Math.floor(endDateYear - startDateYear) + 1;

  const offsets = [...new Array(difference).keys()];
  const years = offsets.map(offset => offset + startDateYear);

  return years;
}
