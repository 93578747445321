import styled from 'styled-components';
import { propStyling, PropsWithPropStyling } from '../../../shared/propStyling';
import { TextStyleProps, textStyles } from '../../../shared/textStyles';
import { tombac } from '../../../shared/tombac';

export const ModalHeader = styled.div<PropsWithPropStyling<TextStyleProps>>`
  all: initial;
  display: block;
  margin: ${tombac.space(3, 3, 0)};
  ${tombac.altText({ fontWeight: 'bold', fontSize: 16 })}

  &:last-child {
    margin-bottom: ${tombac.space(3)};
  }

  ${propStyling}
  ${textStyles}
`;
