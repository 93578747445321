import { createGlobalStyle } from 'styled-components';
import { tombac } from '../../shared/tombac';

export const ModalStyles = createGlobalStyle`
  .TombacModal {
    outline: none;
    position: absolute;
  }

  .TombacModal__body--open {
    overflow: hidden;
  }

  .TombacModal__overlay {
    align-items: center;
    background-color: ${tombac.alpha('neutral', 32)};
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    ${({ theme }) =>
      theme.settings?.modalZIndex !== undefined
        ? { zIndex: theme.settings.modalZIndex }
        : undefined}
  }

/* Transitions */


/* top slide in */

  .TombacModal--top-slide-in {
    opacity: 0;
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    transition-property: opacity, transform;
    transform: translateY(-25vh);
  }

  .TombacModal--after-open-top-slide-in {
    opacity: 1;
    transform: translateY(0);
  }

  .TombacModal--before-close-top-slide-in {
    opacity: 0;
    transform: translateY(-25vh);
  }

  .TombacModal__overlay--top-slide-in {
    opacity: 0;
    transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .TombacModal__overlay--after-open-top-slide-in {
    opacity: 1;
  }

  .TombacModal__overlay--before-close-top-slide-in {
    opacity: 0;
  }

  /* fade in scale */

  .TombacModal--fade-in-scale {
    opacity: 0;
    transition: 0.3s ease-in-out;
    transition-property: opacity, transform;
    transform: scale(0);
  }

  .TombacModal--after-open-fade-in-scale {
    opacity: 1;
    transform: scale(1);
  }

  .TombacModal--before-close--fade-in-scale {
    opacity: 0;
    transform: scale(0);
  }


  .TombacModal__overlay--fade-in-scale {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .TombacModal__overlay--after-open-fade-in-scale {
    opacity: 1;
  }

  .TombacModal__overlay--before-close-fade-in-scale {
    opacity: 0;
  }
`;
