import { FC, useEffect } from 'react';
import nowayBoldTtf from './font/Noway-Bold.ttf';
import nowayBoldWoff from './font/Noway-Bold.woff';
import nowayBoldWoff2 from './font/Noway-Bold.woff2';
import nowayLightTtf from './font/Noway-Light.ttf';
import nowayLightWoff from './font/Noway-Light.woff';
import nowayLightWoff2 from './font/Noway-Light.woff2';
import nowayMediumTtf from './font/Noway-Medium.ttf';
import nowayMediumWoff from './font/Noway-Medium.woff';
import nowayMediumWoff2 from './font/Noway-Medium.woff2';
import nowayThinTtf from './font/Noway-Thin.ttf';
import nowayThinWoff from './font/Noway-Thin.woff';
import nowayThinWoff2 from './font/Noway-Thin.woff2';
import nowayTtf from './font/Noway.ttf';
import nowayWoff from './font/Noway.woff';
import nowayWoff2 from './font/Noway.woff2';
import nowayBoldItalicTtf from './font/NowayBold-Italic.ttf';
import nowayBoldItalicWoff from './font/NowayBold-Italic.woff';
import nowayBoldItalicWoff2 from './font/NowayBold-Italic.woff2';
import nowayLightItalicTtf from './font/NowayLight-Italic.ttf';
import nowayLightItalicWoff from './font/NowayLight-Italic.woff';
import nowayLightItalicWoff2 from './font/NowayLight-Italic.woff2';
import nowayMediumItalicTtf from './font/NowayMedium-Italic.ttf';
import nowayMediumItalicWoff from './font/NowayMedium-Italic.woff';
import nowayMediumItalicWoff2 from './font/NowayMedium-Italic.woff2';
import nowayItalicTtf from './font/NowayRegular-Italic.ttf';
import nowayItalicWoff from './font/NowayRegular-Italic.woff';
import nowayItalicWoff2 from './font/NowayRegular-Italic.woff2';
import nowayThinItalicTtf from './font/NowayThin-Italic.ttf';
import nowayThinItalicWoff from './font/NowayThin-Italic.woff';
import nowayThinItalicWoff2 from './font/NowayThin-Italic.woff2';

export const NowayFont: FC = () => {
  useEffect(() => {
    if (document.querySelector('style[data-tombac-style="noway"]')) return;

    const styleElement = document.createElement('style');
    styleElement.setAttribute('data-tombac-style', 'noway');
    styleElement.innerHTML = `@font-face {
  font-family: "Noway";
  font-style: normal;
  font-weight: 100;
  src: url(${nowayThinWoff2}) format("woff2"),
    url(${nowayThinWoff}) format("woff"),
    url(${nowayThinTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: italic;
  font-weight: 100;
  src: url(${nowayThinItalicWoff2}) format("woff2"),
    url(${nowayThinItalicWoff}) format("woff"),
    url(${nowayThinItalicTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: normal;
  font-weight: 300;
  src: url(${nowayLightWoff2}) format("woff2"),
    url(${nowayLightWoff}) format("woff"),
    url(${nowayLightTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: italic;
  font-weight: 300;
  src: url(${nowayLightItalicWoff2}) format("woff2"),
    url(${nowayLightItalicWoff}) format("woff"),
    url(${nowayLightItalicTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: normal;
  font-weight: 400;
  src: url(${nowayWoff2}) format("woff2"),
    url(${nowayWoff}) format("woff"),
    url(${nowayTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: italic;
  font-weight: 400;
  src: url(${nowayItalicWoff2}) format("woff2"),
    url(${nowayItalicWoff}) format("woff"),
    url(${nowayItalicTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: normal;
  font-weight: 500;
  src: url(${nowayMediumWoff2}) format("woff2"),
    url(${nowayMediumWoff}) format("woff"),
    url(${nowayMediumTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: italic;
  font-weight: 500;
  src: url(${nowayMediumItalicWoff2}) format("woff2"),
    url(${nowayMediumItalicWoff}) format("woff"),
    url(${nowayMediumItalicTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: normal;
  font-weight: 700;
  src: url(${nowayBoldWoff2}) format("woff2"),
    url(${nowayBoldWoff}) format("woff"),
    url(${nowayBoldTtf}) format("truetype");
}
@font-face {
  font-family: "Noway";
  font-style: italic;
  font-weight: 700;
  src: url(${nowayBoldItalicWoff2}) format("woff2"),
    url(${nowayBoldItalicWoff}) format("woff"),
    url(${nowayBoldItalicTtf}) format("truetype");
}`;

    document.head.appendChild(styleElement);
  }, []);
  return null;
};
