import cx from 'classnames';
import React, {
  FC,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { tombac } from '../../shared';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { extractStylingProps } from '../../shared/propStyling/propStylingUtils';

export interface CheckboxCoreProps {
  /**
   * Sets the the indeterminate look. When true, the underlying checkbox is unchecked.
   * Does not affect toggle variant.
   */
  indeterminate?: boolean;
  /**
   * Wraps the checkbox in a <label> element and displays the provided text next to it.
   * If false, the checkbox is wrapped in a <span> element instead, so that you can wrap it
   * into a label element yourself.
   */
  label?: string | ReactElement | boolean;
  labelPlacement?: 'after' | 'before';
  /** Size of the toggle. It has no effect on the default checkbox variant. */
  size?: 's' | 'xs';
  /** Visual variant of the checkbox. */
  variant?: 'checkbox' | 'toggle';
}

export type CheckboxProps = PropsWithPropStyling<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & CheckboxCoreProps
>;

const Container = styled.span<PropsWithPropStyling<{ disabled?: boolean }>>`
  all: initial;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: inline-block;

  ${({ disabled }) =>
    tombac.text({
      color: disabled ? tombac.alpha('neutral', 24) : undefined,
      fontSize: 14,
    })}
  ${propStyling}
`;

const Input = styled.input`
  all: unset;
  height: 0;
  margin: 0;
  opacity: 0;
  width: 0;
`;

const Label = styled.span<{ placement: CheckboxProps['labelPlacement'] }>`
  all: unset;
  margin-left: ${({ placement }) =>
    placement === 'after' ? tombac.space(1) : undefined};
  margin-right: ${({ placement }) =>
    placement === 'before' ? tombac.space(1) : undefined};
`;

const StyledCheckbox = styled.span`
  all: unset;
  border-radius: ${tombac.unit(3)};
  display: inline-block;
  height: ${tombac.unit(18)};
  width: ${tombac.unit(18)};
  transition: 0.1s ease-out;
  transition-property: box-shadow, margin, padding;
  vertical-align: ${tombac.unit(-3)};

  &::before {
    background-color: ${tombac.color.white};
    border: ${tombac.unit(1)} solid ${tombac.color('neutral', 400)};
    border-radius: ${tombac.unit(1)};
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    transition: 0.1s ease-out;
    transition-property: background-color, border-color;
    width: 100%;
  }

  &::after {
    border: 0 solid transparent;
    border-radius: ${tombac.unit(1)};
    box-sizing: border-box;
    content: '';
    display: block;
    height: 0;
    margin: -50% 0 0 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: 0.1s ease-out;
    transition-property: border-color, border-width, height, transform,
      transform-origin, width;
    width: 0;
  }

  ${Input}:disabled + &::before {
    background-color: ${tombac.color('neutral', 400)};
  }

  ${Input}:focus + &,
  ${Input}:focus-visible + & {
    background-color: ${tombac.color('accent', 300)};
    box-shadow: ${tombac.unit(0, 1, 3, 0)} hsla(206, 70%, 74%, 0.24);
    margin: ${tombac.unit(-3)};
    padding: ${tombac.unit(3)};
  }

  ${Input}:focus:not(:focus-visible) + & {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  ${Input}:focus + &::before,
  ${Input}:focus-visible + &::before {
    background-color: ${tombac.color('accent', 200)};
    border-color: ${tombac.color('accent', 500)};
  }

  ${Input}:focus:not(:focus-visible) + &::before {
    background-color: ${tombac.color.white};
    border-color: ${tombac.color('neutral', 400)};
  }

  ${Input}:not(:disabled) + &:hover::before {
    background-color: ${tombac.color('neutral', 300)};
    border-color: ${tombac.color('neutral', 500)};
  }

  ${Input}:focus + &:hover::before,
  ${Input}:focus-visible + &:hover::before {
    background-color: ${tombac.color('accent', 200)};
    border-color: ${tombac.color('accent', 600)};
  }

  ${Input}:focus:not(:focus-visible) + &:hover::before {
    background-color: ${tombac.color('neutral', 300)};
    border-color: ${tombac.color('neutral', 500)};
  }

  ${Input}:not(:disabled):checked + &::before,
  ${Input}:not(:disabled):indeterminate + &::before {
    background-color: ${tombac.color('accent', 500)};
    border-color: ${tombac.color('accent', 500)};
  }

  ${Input}:not(:disabled):checked + &:hover::before,
  ${Input}:not(:disabled):indeterminate + &:hover::before {
    background-color: ${tombac.color('accent', 600)};
    border-color: ${tombac.color('accent', 600)};
  }

  ${Input}:checked + &::after,
  ${Input}:indeterminate + &::after {
    border-color: ${tombac.color.white};
    height: ${tombac.space(1)};
  }

  ${Input}:disabled:checked + &::after,
  ${Input}:disabled:indeterminate + &::after {
    border-color: ${tombac.alpha('neutral', 24)};
  }

  ${Input}:checked + &::after {
    border-width: ${tombac.unit(0, 2, 2, 0)};
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: 75% 50%;
    width: ${tombac.space(0.625)};
  }

  ${Input}:indeterminate + &::after {
    border-width: ${tombac.unit(0, 2, 0, 0)};
    transform: translate(-50%, -50%) rotate(90deg);
    transform-origin: 50% 50%;
    width: 0;
  }
`;

const StyledToggle = styled.span<Required<Pick<CheckboxProps, 'size'>>>`
  all: unset;
  background-color: ${tombac.color('neutral', 500)};
  border: ${tombac.unit(1)} solid transparent;
  border-radius: ${tombac.unit(12)};
  box-sizing: border-box;
  display: inline-block;
  height: ${({ size }) => tombac.unit(size === 'xs' ? 16 : 24)};
  position: relative;
  transition: 0.1s ease-out;
  transition-property: background-color, border;
  vertical-align: ${({ size }) => tombac.unit(size === 'xs' ? -3 : -7)};
  width: ${({ size }) => tombac.unit(size === 'xs' ? 32 : 46)};

  &::before {
    border: 0 solid transparent;
    border-radius: ${tombac.unit(16)};
    bottom: 0;
    content: '';
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.1s ease-out;
    transition-property: border, box-shadow, margin;
    z-index: 0;
  }

  &::after {
    background-color: ${tombac.color.white};
    box-shadow: ${tombac.unit(0, 1, 4, 0)} ${tombac.alpha('neutral', 24)};
    content: '';
    display: block;
    border-radius: 100%;
    height: ${({ size }) => tombac.unit(size === 'xs' ? 14 : 20)};
    left: ${({ size }) => tombac.unit(size === 'xs' ? 0 : 1)};
    position: absolute;
    width: ${({ size }) => tombac.unit(size === 'xs' ? 14 : 20)};
    top: ${({ size }) => tombac.unit(size === 'xs' ? 0 : 1)};
    transition: left 0.1s ease-out;
  }

  &:hover {
    background-color: ${tombac.color('neutral', 600)};
  }

  ${Input}:focus + &,
  ${Input}:focus-visible + & {
    border-color: ${tombac.color('accent', 500)};
  }

  ${Input}:focus:not(:focus-visible) + & {
    border-color: transparent;
  }

  ${Input}:focus + &::before,
  ${Input}:focus-visible + &::before {
    border: ${tombac.unit(3)} solid ${tombac.color('accent', 300)};
    box-shadow: ${tombac.unit(0, 1, 3, 0)} hsla(206, 70%, 74%, 0.24);
    margin: ${tombac.unit(-4)};
  }

  ${Input}:focus:not(:focus-visible) + &::before {
    border: 0 solid transparent;
    box-shadow: none;
    margin: 0;
  }

  ${Input}:checked + & {
    background-color: ${tombac.color('accent', 500)};
  }

  ${Input}:checked + &:hover {
    background-color: ${tombac.color('accent', 600)};
  }

  ${Input}:checked + &&::after {
    left: ${({ size }) => tombac.unit(size === 'xs' ? 16 : 23)};
  }

  ${Input}:disabled + & {
    background-color: ${tombac.color('neutral', 400)} !important;
  }

  ${Input}:disabled + &::after {
    background-color: ${tombac.color('neutral', 500)};
    box-shadow: none;
  }
`;

export const Checkbox: FC<CheckboxProps> = ({
  className,
  checked,
  disabled,
  indeterminate = false,
  label = true,
  labelPlacement = 'after',
  size = 's',
  style,
  variant = 'checkbox',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [stylingProps, nonStylingProps] = extractStylingProps(rest);

  const handleMouseDown = useCallback<MouseEventHandler>(
    (e) => e.preventDefault(),
    [],
  );

  useEffect(
    function handleIndeterminate() {
      const checkbox = inputRef.current!;
      const setIndeterminate = () => {
        checkbox.indeterminate = indeterminate;
      };
      setIndeterminate();
      checkbox.addEventListener('change', setIndeterminate);
      return () => {
        checkbox.removeEventListener('change', setIndeterminate);
      };
    },
    [checked, indeterminate],
  );

  const displayLabel = typeof label !== 'boolean';

  return (
    <Container
      as={label === false ? undefined : 'label'}
      className={cx('TombacCheckbox', className)}
      disabled={disabled}
      onMouseDown={handleMouseDown}
      style={style}
      {...stylingProps}
    >
      {displayLabel && labelPlacement === 'before' && (
        <>
          <Label placement="before">{label}</Label>{' '}
        </>
      )}
      <Input
        checked={indeterminate ? false : checked}
        disabled={disabled}
        ref={inputRef}
        type="checkbox"
        {...nonStylingProps}
      />
      {variant === 'checkbox' && <StyledCheckbox />}
      {variant === 'toggle' && <StyledToggle size={size} />}
      {displayLabel && labelPlacement === 'after' && (
        <>
          {' '}
          <Label placement="after">{label}</Label>
        </>
      )}
    </Container>
  );
};

export const Toggle: FC<Omit<CheckboxProps, 'indeterminate' | 'variant'>> = (
  props,
) => <Checkbox {...props} variant="toggle" />;
