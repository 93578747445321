import {
  addPadding,
  divideIntoWeeks,
  EMPTY_DAY,
  generateDaysForMonth,
} from './monthCalendarUtils';
import { Weekdays } from './Weekdays';

const { MONDAY } = Weekdays;

const defaultOptions = {
  weekStartsOn: MONDAY,
  showFullDates: false,
};

interface Options {
  weekStartsOn?: Weekdays;
  showFullDates?: boolean;
}

function monthCalendar(year: number, month: number, options?: Options) {
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  const days = generateDaysForMonth(year, month);
  const weeks = divideIntoWeeks(days, mergedOptions);

  const weeksWithPadding = addPadding(weeks);

  return weeksWithPadding;
}

export { EMPTY_DAY, monthCalendar };
