import React, { ElementType, ReactNode, useContext } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SpinnerIcon } from 'tombac-icons';
import { TombacIconProps } from 'tombac-icons/react/tombacIcon';
import { propStyling, PropsWithPropStyling } from '../../shared/propStyling';
import { tombac } from '../../shared/tombac';
import { ButtonGroupContext } from './ButtonGroupContext';
import { createRipple } from './createRipple';

export type ButtonSize = 'm' | 's' | 'xs';

export interface ButtonCoreProps {
  /** Append any content after the button label. */
  append?: ReactNode;
  /** Show busy indicator. */
  busy?: boolean;
  /** Disable the button. If the button is an anchor, it will be replaced with a disabled button element. */
  disabled?: boolean;
  /** Prepend any content before the button label. */
  prepend?: ReactNode;
  /** Size of the button. */
  size?: ButtonSize;
  /** Make the button a square or circle shaped. Useful for rendering icon-only buttons. */
  shape?: 'square' | 'circle';
  /** @deprecated Use "shape" prop instead. */
  square?: boolean;
  /** Variant of the button. */
  variant?:
    | 'accent'
    | 'alert'
    | 'danger'
    | 'flat'
    | 'ghost'
    | 'primary'
    | 'secondary'
    | 'success';
}

export type ButtonProps = PropsWithPropStyling<ButtonCoreProps>;

const baseStyles = css<ButtonProps>`
  all: initial;
  will-change: transform; //fix ripple effect for safari
  position: relative;
  overflow: hidden;
  align-items: center;
  border-radius: ${({ shape }) =>
    shape === 'circle' ? '100%' : tombac.unit(1)};
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: inline-flex;
  justify-content: center;
  text-align: center;
  transition: 0.1s ease-out;
  transition-property: background-color, border-color, box-shadow;
  white-space: nowrap;
  vertical-align: middle;

  &:focus,
  &:focus-visible {
    box-shadow: 0 0 0 ${tombac.unit(4)} ${tombac.color('accent', 200)};
    z-index: 1;
  }

  &:focus:not(:focus-visible) {
    box-shadow: initial;
    z-index: initial;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

const disabledStyles = css<ButtonProps>`
  background-color: ${tombac.color('neutral', 400)};
  color: ${tombac.alpha('neutral', 24)};
  ${({ variant }) =>
    variant === 'ghost' &&
    css`
      border: ${tombac.unit(1)} solid ${tombac.color('neutral', 400)};
    `}
`;

// Animation is synchronized with setTimeout value in createRipple.
const rippleKeyframes = keyframes`
  50% {
    transform: scale(3);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const rippleStyles = css`
  .TombacButton__ripple {
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    animation: ${rippleKeyframes} 1s ease-out;
    transform: scale(0);
  }

  .TombacButton__ripple--active {
    animation: ${rippleKeyframes} 1s ease-out;
  }
`;

const sizeStyles = tombac.variant<ButtonProps>('size', {
  m: css<ButtonProps>`
    ${tombac.altText({ fontSize: 14, fontWeight: 500, lineHeight: 1.2 })}
    height: ${tombac.space(5)};
    min-width: ${tombac.space(5)};
    padding: 0
      ${({ append, busy, prepend, shape, square }) =>
        tombac.space(square || shape ? 0 : append || busy || prepend ? 1 : 3)};
  `,
  s: css<ButtonProps>`
    ${tombac.altText({ fontSize: 14, fontWeight: 500, lineHeight: 1.2 })}
    height: ${tombac.space(4)};
    min-width: ${tombac.space(4)};
    padding: 0
      ${({ append, busy, prepend, shape, square }) =>
        tombac.space(
          square || shape ? 0 : append || busy || prepend ? 0.75 : 2,
        )};
  `,
  xs: css<ButtonProps>`
    ${tombac.altText({ fontSize: 12, fontWeight: 500, lineHeight: 1.2 })}
    height: ${tombac.space(3)};
    min-width: ${tombac.space(3)};
    padding: 0
      ${({ append, busy, prepend, shape, square }) =>
        tombac.space(
          square || shape ? 0 : append || busy || prepend ? 0.5 : 1,
        )};
  `,
});

const variantStyles = tombac.variant<ButtonProps>('variant', {
  accent: css`
    background-color: ${tombac.color('accent')};
    color: ${tombac.color.white};
    & > .TombacButton__ripple {
      background-color: ${tombac.color('accent')};
    }
    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('accent', 600)};
    }
  `,
  alert: css`
    background-color: ${tombac.color('alert')};
    color: ${tombac.color.white};
    & > .TombacButton__ripple {
      background-color: ${tombac.color('alert')};
    }
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 ${tombac.unit(4)} ${tombac.color('alert', 200)};
    }
    &:focus:not(:focus-visible) {
      box-shadow: initial;
    }
    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('alert', 600)};
    }
  `,
  danger: css`
    background-color: ${tombac.color('danger')};
    color: ${tombac.color.white};
    & > .TombacButton__ripple {
      background-color: ${tombac.color('danger')};
    }
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 ${tombac.unit(4)} ${tombac.color('danger', 200)};
    }
    &:focus:not(:focus-visible) {
      box-shadow: initial;
    }
    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('danger', 600)};
    }
  `,
  flat: css`
    background-color: ${tombac.color.white};
    color: ${tombac.color.black};
    & > .TombacButton__ripple {
      background-color: ${tombac.color.white};
    }
    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('neutral', 300)};
    }
  `,
  ghost: css`
    background-color: ${tombac.color.white};
    border: ${tombac.unit(1)} solid ${tombac.color('neutral', 400)};
    color: ${tombac.color.black};
    & > .TombacButton__ripple {
      background-color: ${tombac.color.white};
    }
    &:focus,
    &:focus-visible {
      border-color: ${tombac.color.white};
    }
    &:focus:not(:focus-visible) {
      border-color: ${tombac.color('neutral', 400)};
    }
    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('neutral', 300)};
      border-color: ${tombac.color('neutral', 300)};
    }
  `,
  primary: css`
    background-color: ${tombac.color('primary')};
    color: ${tombac.color.white};
    & > .TombacButton__ripple {
      background-color: ${tombac.color('primary')};
    }
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 ${tombac.unit(4)} ${tombac.color('primary', 200)};
    }
    &:focus:not(:focus-visible) {
      box-shadow: initial;
    }

    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('primary', 600)};
    }
  `,
  secondary: css`
    background-color: ${tombac.color('neutral', 300)};
    color: ${tombac.color.black};
    & > .TombacButton__ripple {
      background-color: ${tombac.color('neutral', 300)};
    }
    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('neutral', 400)};
    }
  `,
  success: css`
    background-color: ${tombac.color('success')};
    color: ${tombac.color.white};
    & > .TombacButton__ripple {
      background-color: ${tombac.color('success')};
    }
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 ${tombac.unit(4)} ${tombac.color('success', 200)};
    }
    &:focus:not(:focus-visible) {
      box-shadow: initial;
    }
    &:not([disabled]):hover,
    &[data-ripple='active'] {
      background-color: ${tombac.color('success', 600)};
    }
  `,
});

const Label = styled.span<
  Pick<ButtonProps, 'append' | 'busy' | 'prepend' | 'size'>
>`
  margin: ${({ append, busy, prepend, size }) =>
    tombac.space(
      append || busy || prepend
        ? size === 'xs'
          ? 0.5
          : size === 's'
          ? 0.75
          : 1
        : 0,
    )};
`;

const StyledSpinner = styled((props: Omit<TombacIconProps, 'size'>) => (
  <SpinnerIcon {...props} />
))<Pick<ButtonProps, 'size'>>`
  height: ${({ size }) => tombac.unit(size === 'xs' ? 17 : 20)};
  width: ${({ size }) => tombac.unit(size === 'xs' ? 20 : 24)};
`;

const StyledContent = styled.span`
  position: relative;
  z-index: 1;

  display: inline-flex;
  align-items: center;
`;

export const Button = styled.button.attrs<ButtonProps & { as: ElementType }>(
  ({
    append,
    as,
    busy,
    children,
    disabled,
    prepend,
    size,
    theme,
    variant,
    onClick,
  }) => {
    const groupContext = useContext(ButtonGroupContext);
    return {
      as: as === 'a' && disabled ? 'button' : as,
      children: (
        <StyledContent>
          {append || prepend ? (
            <>
              {prepend && (
                <>{busy ? <StyledSpinner size={size} spin /> : prepend} </>
              )}
              {children && (
                <Label {...{ append, busy, prepend, size }}>{children}</Label>
              )}
              {append && (
                <>
                  {' '}
                  {busy && !prepend ? (
                    <StyledSpinner size={size} spin />
                  ) : (
                    append
                  )}
                </>
              )}
            </>
          ) : busy ? (
            <>
              <StyledSpinner size={size} spin />{' '}
              <Label {...{ append, busy, prepend, size }}>{children}</Label>
            </>
          ) : (
            children
          )}
        </StyledContent>
      ),
      className: `TombacButton TombacButton--variant-${variant}`,
      disabled: disabled || busy,
      size: groupContext.size ?? size ?? theme.settings?.buttonSize ?? 'm',
      onClick: createRipple(onClick),
    };
  },
)<ButtonProps>`
  ${baseStyles}
  ${sizeStyles}
  ${rippleStyles}
  ${({ busy, disabled }) =>
    disabled && !busy ? disabledStyles : variantStyles}
  ${propStyling}
`;

Button.defaultProps = {
  variant: 'secondary',
};
