import { TombacTheme } from '../shared/tombac/tombacTypes';

export const defaultColorPalette = {
  lightRed: 'hsl(1, 79%, 59%)',
  brandRed: 'hsl(3, 85%, 47%)',
  darkRed: 'hsl(1, 85%, 37%)',

  lightTeal: 'hsl(182, 28%, 53%)',
  brandTeal: 'hsl(180, 100%, 28%)',
  darkTeal: 'hsl(179, 100%, 21%)',

  lightIndigo: 'hsl(215, 30%, 51%)',
  brandIndigo: 'hsl(205, 100%, 25%)',
  darkIndigo: 'hsl(212, 100%, 18%)',

  lightBlue: 'hsl(206, 74%, 85%)',
  brandBlue: 'hsl(206, 70%, 74%)',
  darkBlue: 'hsl(204, 67%, 63%)',

  lightPink: 'hsl(332, 77%, 88%)',
  brandPink: 'hsl(333, 77%, 78%)',
  darkPink: 'hsl(333, 79%, 65%)',

  lightYellow: 'hsl(44, 100%, 74%)',
  brandYellow: 'hsl(44, 98%, 59%)',
  darkYellow: 'hsl(40, 95%, 56%)',
};

export const defaultTheme: TombacTheme = {
  baseSpace: 8,
  baseUnit: 'rem',
  colors: {
    accent: {
      100: 'hsl(210, 80%, 98%)',
      200: 'hsl(208, 76%, 95%)',
      300: 'hsl(206, 74%, 85%)',
      400: 'hsl(206, 70%, 74%)',
      500: 'hsl(204, 67%, 63%)',
      600: 'hsl(204, 67%, 50%)',
      700: 'hsl(204, 68%, 40%)',
      800: 'hsl(204, 67%, 30%)',
      900: 'hsl(204, 67%, 20%)',
      alpha: {
        8: 'hsla(204, 68%, 60%, 0.08)',
        16: 'hsla(204, 68%, 60%, 0.16)',
        24: 'hsla(204, 68%, 60%, 0.24)',
        32: 'hsla(204, 68%, 60%, 0.32)',
        40: 'hsla(204, 68%, 60%, 0.4)',
        48: 'hsla(204, 67%, 63%, 0.48)',
      },
    },
    alert: {
      100: 'hsl(51, 100%, 97%)',
      200: 'hsl(46, 100%, 88%)',
      300: 'hsl(44, 100%, 81%)',
      400: 'hsl(44, 100%, 74%)',
      500: 'hsl(44, 98%, 59%)',
      600: 'hsl(40, 95%, 56%)',
      700: 'hsl(40, 82%, 37%)',
      800: 'hsl(39, 88%, 26%)',
      900: 'hsl(39, 85%, 18%)',
      alpha: {
        8: 'hsla(40, 100%, 50%, 0.08)',
        16: 'hsla(40, 100%, 50%, 0.16)',
        24: 'hsla(40, 100%, 50%, 0.24)',
        32: 'hsla(40, 100%, 50%, 0.32)',
        40: 'hsla(40, 100%, 50%, 0.4)',
        48: 'hsla(40, 100%, 50%, 0.48)',
      },
    },
    neutral: {
      100: 'hsl(0, 0%, 100%)',
      200: 'hsl(0, 0%, 98%)',
      300: 'hsl(0, 0%, 95%)',
      400: 'hsl(0, 0%, 90%)',
      500: 'hsl(0, 0%, 75%)',
      600: 'hsl(200, 2%, 49%)',
      700: 'hsl(180, 1%, 29%)',
      800: 'hsl(0, 0%, 20%)',
      900: 'hsl(0, 0%, 0%)',
      alpha: {
        8: 'hsla(0, 0%, 0%, 0.08)',
        16: 'hsla(0, 0%, 0%, 0.16)',
        24: 'hsla(0, 0%, 0%, 0.24)',
        32: 'hsla(0, 0%, 0%, 0.32)',
        40: 'hsla(0, 0%, 0%, 0.4)',
        48: 'hsla(0, 0%, 0%, 0.48)',
      },
      invertedAlpha: {
        8: 'hsla(0, 0%, 100%, 0.08)',
        16: 'hsla(0, 0%, 100%, 0.16)',
        24: 'hsla(0, 0%, 100%, 0.24)',
        32: 'hsla(0, 0%, 100%, 0.32)',
        40: 'hsla(0, 0%, 100%, 0.4)',
        48: 'hsla(0, 0%, 100%, 0.48)',
      },
    },
    primary: {
      100: 'hsl(0, 100%, 96%)',
      200: 'hsl(1, 100%, 83%)',
      300: 'hsl(2, 100%, 73%)',
      400: 'hsl(1, 79%, 59%)',
      500: 'hsl(3, 85%, 47%)',
      600: 'hsl(1, 85%, 37%)',
      700: 'hsl(1, 94%, 26%)',
      800: 'hsl(1, 100%, 18%)',
      900: 'hsl(2, 100%, 14%)',
      alpha: {
        8: 'hsla(344, 100%, 62%, 0.08)',
        16: 'hsla(344, 100%, 62%, 0.16)',
        24: 'hsla(344, 100%, 62%, 0.24)',
        32: 'hsla(344, 100%, 62%, 0.32)',
        40: 'hsla(344, 100%, 62%, 0.4)',
        48: 'hsla(344, 100%, 62%, 0.48)',
      },
    },
    success: {
      100: 'hsl(134, 100%, 95%)',
      200: 'hsl(132, 85%, 92%)',
      300: 'hsl(132, 100%, 88%)',
      400: 'hsl(132, 91%, 79%)',
      500: 'hsl(132, 64%, 66%)',
      600: 'hsl(132, 43%, 60%)',
      700: 'hsl(133, 35%, 54%)',
      800: 'hsl(133, 33%, 45%)',
      900: 'hsl(132, 35%, 32%)',
      alpha: {
        8: 'hsla(132, 64%, 66%, 0.08)',
        16: 'hsla(132, 64%, 66%, 0.16)',
        24: 'hsla(132, 64%, 66%, 0.24)',
        32: 'hsla(132, 64%, 66%, 0.32)',
        40: 'hsla(132, 64%, 66%, 0.4)',
        48: 'hsla(132, 64%, 66%, 0.48)',
      },
    },
    danger: {
      100: 'hsl(0, 100%, 96%)',
      200: 'hsl(1, 100%, 83%)',
      300: 'hsl(2, 100%, 73%)',
      400: 'hsl(1, 79%, 59%)',
      500: 'hsl(3, 85%, 47%)',
      600: 'hsl(1, 85%, 37%)',
      700: 'hsl(1, 94%, 26%)',
      800: 'hsl(1, 100%, 18%)',
      900: 'hsl(2, 100%, 14%)',
      alpha: {
        8: 'hsla(344, 100%, 62%, 0.08)',
        16: 'hsla(344, 100%, 62%, 0.16)',
        24: 'hsla(344, 100%, 62%, 0.24)',
        32: 'hsla(344, 100%, 62%, 0.32)',
        40: 'hsla(344, 100%, 62%, 0.4)',
        48: 'hsla(344, 100%, 62%, 0.48)',
      },
    },
  },
  typography: {
    altFontFamily: 'Gotham, sans-serif',
    defaultColor: ['neutral', 900],
    defaultFontSize: 14,
    defaultFontWeight: '400',
    defaultLineHeight: 1.5,
    defaultFontFamily: 'Noway, sans-serif',
  },
};
