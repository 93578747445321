import cx from 'classnames';
import React, { FC, HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { tombac } from '../../shared/tombac';
import { Caption, InlineText, Label } from '../Typography';

export interface FormGroupCoreProps {
  alert?: ReactNode;
  caption?: ReactNode;
  danger?: ReactNode;
  error?: ReactNode;
  hint?: ReactNode;
  htmlFor?: string;
  label?: ReactNode;
  layout?: 'default' | 'inline';
  required?: boolean;
  toolbar?: ReactNode;
  variant?: 'default' | 'alert' | 'danger';
}

export type FormGroupProps = PropsWithPropStyling<
  HTMLAttributes<HTMLElement> & FormGroupCoreProps
>;

const CaptionArea = styled.div<Pick<FormGroupProps, 'layout'>>`
  all: unset;
  display: block;
  grid-area: caption;
  margin-top: ${({ layout }) =>
    layout === 'inline' ? undefined : tombac.space(0.5)};
`;

const Container = styled.div<FormGroupProps>`
  all: initial;
  align-items: baseline;
  display: inline-grid;
  gap: ${tombac.space(0, 2)};
  grid-template: ${({ layout }) =>
    layout === 'inline'
      ? `
    'label input toolbar caption'
    'hint input toolbar caption'
    / max-content 1fr max-content max-content`
      : `
    'label toolbar'
    'hint hint'
    'input input'
    'caption caption'
    / 1fr max-content`};
  ${propStyling}
`;

const ChildrenArea = styled.div<Pick<FormGroupProps, 'layout'>>`
  all: unset;
  display: flex;
  grid-area: input;

  ${({ layout }) =>
    layout !== 'inline' &&
    css`
      && > * {
        flex: auto;
      }
    `}
`;

const HintArea = styled.div<Pick<FormGroupProps, 'layout'>>`
  all: unset;
  align-self: ${({ layout }) =>
    layout === 'inline' ? 'flex-start' : undefined};
  display: block;
  grid-area: hint;
  margin-bottom: ${({ layout }) =>
    layout === 'inline' ? undefined : tombac.space(0.5)};
`;

const LabelArea = styled.div<Pick<FormGroupProps, 'hint' | 'layout'>>`
  all: unset;
  align-self: ${({ hint, layout }) =>
    layout === 'inline' && hint ? 'flex-end' : undefined};
  display: block;
  grid-area: label;
  min-height: ${({ layout }) =>
    layout === 'inline' ? undefined : tombac.space(3)};

  & > * {
    vertical-align: middle;
  }
`;

const Toolbar = styled.div`
  all: unset;
  display: block;
  grid-area: toolbar;
`;

export const FormGroup: FC<FormGroupProps> = ({
  alert,
  error,
  danger = error,
  caption = danger || alert || undefined,
  className,
  children,
  hint,
  htmlFor,
  label,
  layout = 'default',
  required,
  toolbar,
  variant = danger ? 'danger' : alert ? 'alert' : 'default',
  ...rest
}) => (
  <Container
    className={cx('TombacFormGroup', className)}
    layout={layout}
    {...rest}
  >
    {label !== undefined && (
      <LabelArea hint={hint} layout={layout}>
        {typeof label === 'string' ? (
          <Label htmlFor={htmlFor} required={required} variant={variant}>
            {label}
          </Label>
        ) : (
          label
        )}
      </LabelArea>
    )}
    {hint !== undefined && (
      <HintArea layout={layout}>
        {typeof hint === 'string' ? (
          <Caption variant="secondary">{hint}</Caption>
        ) : (
          hint
        )}
      </HintArea>
    )}
    {toolbar !== undefined && (
      <Toolbar>
        {typeof toolbar === 'string' ? (
          <InlineText>{toolbar}</InlineText>
        ) : (
          toolbar
        )}
      </Toolbar>
    )}
    {children && <ChildrenArea layout={layout}>{children}</ChildrenArea>}
    {caption !== undefined && (
      <CaptionArea layout={layout}>
        {typeof caption === 'string' ? (
          <Caption variant={variant}>{caption}</Caption>
        ) : (
          caption
        )}
      </CaptionArea>
    )}
  </Container>
);
