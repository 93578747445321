import { createPropStyling, PropStylingProps } from '../privatePropStylingUtils';

export const borderCssAttrNames = [
  'border',
  'borderBottom',
  'borderBottomColor',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderBottomStyle',
  'borderBottomWidth',
  'borderColor',
  'borderLeft',
  'borderLeftColor',
  'borderLeftStyle',
  'borderLeftWidth',
  'borderRadius',
  'borderRight',
  'borderRightColor',
  'borderRightStyle',
  'borderRightWidth',
  'borderStyle',
  'borderTop',
  'borderTopColor',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderTopStyle',
  'borderTopWidth',
  'borderWidth',
] as const;

export type BorderCssAttrName = typeof borderCssAttrNames[number];

export type BorderPropStylingProps = PropStylingProps<BorderCssAttrName>;

export const borderPropStyling = createPropStyling<BorderPropStylingProps>(borderCssAttrNames);
