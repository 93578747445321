import { defaultTheme } from '../../themes/defaultTheme';
import { PropsWithTheme, TombacTheme } from '../tombac/tombacTypes';

export const tombacThemeColors = [
  'accent',
  'alert',
  'neutral',
  'primary',
  'success',
  'danger',
] as const;

export function bindTombacTool<
  T extends (...args: any) => any,
  R = ReturnType<ReturnType<T>>
>(tool: T, theme: Partial<TombacTheme>) {
  return (...args: Parameters<T>) => tool(...args)({ theme }) as R;
}

export function bindTombacStaticTool<
  T extends (props: PropsWithTheme) => any,
  R = ReturnType<T>
>(tool: T, theme: Partial<TombacTheme>) {
  return tool({ theme }) as R;
}

export function pxToUnit(px: number, unit: string) {
  return px === 0 ? '0' : unit === 'rem' ? `${px / 16}rem` : `${px}px`;
}

export function resolveTheme(theme: {}) {
  return { ...defaultTheme, ...theme };
}
