import { Styles } from 'react-select';
import { BoundTombac } from '../../../shared/tombac';

export function reactSelectStyles(tombac: BoundTombac): Partial<Styles> {
  return {
    control: (
      provided,
      { isFocused, selectProps: { invalid, variant, size } },
    ) => ({
      all: 'initial',
      ...provided,
      minHeight: size === 's' ? tombac.space(4) : tombac.space(5),
      height: '100%',
      borderColor: isFocused
        ? provided.borderColor
        : invalid || variant === 'danger'
        ? tombac.color('danger', 500)
        : variant === 'alert'
        ? tombac.color('alert', 500)
        : provided.borderColor,
      boxShadow: undefined,
      transition: '0.1s ease-out',
      transitionProperty: 'background-color, border-color',
      '&:hover': {
        backgroundColor: tombac.color('neutral', 200),
        borderColor: undefined,
      },
    }),
    dropdownIndicator: (provided, { selectProps: { size } }) => ({
      all: 'initial',
      ...provided,
      color: tombac.color('neutral', 900),
      padding: size === 's' ? tombac.space(0.5) : tombac.space(1),
      '&:hover': {
        color: tombac.color('neutral', 900),
      },
    }),
    indicatorSeparator: () => ({}),
    input: (provided) => ({
      all: 'initial',
      ...provided,
      ...tombac.text(),
      marginLeft: tombac.space(1),
      marginRight: tombac.space(1),
      input: {
        fontSize: 14,
        fontFamily: 'inherit',
        paddingTop: '6px !important', // Fixes Noway font alignment
      },
    }),
    menu: (provided) => ({
      all: 'initial',
      ...provided,
      ...tombac.text({ color: tombac.color('neutral', 600) }),
      border: `${tombac.unit(1)} solid ${tombac.color('neutral', 400)}`,
      borderRadius: tombac.unit(2),
      boxShadow: undefined,
      marginTop: tombac.space(0.5),
    }),
    option: (provided, { isFocused, isSelected, isDisabled }) => ({
      all: 'initial',
      ...provided,
      ...tombac.text({
        color: isSelected
          ? tombac.color('accent', 600)
          : isDisabled
          ? tombac.color('neutral', 600) : tombac.color('neutral', 900),
        fontSize: 14,
      }),
      backgroundColor: tombac.color('neutral', 100),
      padding: tombac.space(1, 2),
      paddingTop: `calc(${tombac.space(1)} + 2px)`, // Fixes Noway font alignment
      transition: '0.1s ease-out',
      transitionProperty: 'background-color, color',
      '&:hover': {
        backgroundColor: isSelected || isDisabled ? undefined : tombac.color('neutral', 300),
        cursor: isDisabled ? 'no-drop' : 'pointer',
      },
    }),
    placeholder: (provided) => ({
      all: 'initial',
      ...provided,
      ...tombac.text({ color: tombac.color('neutral', 600) }),
      marginLeft: tombac.space(1),
      marginRight: tombac.space(1),
      paddingTop: '3px !important', // Fixes Noway font alignment
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided) => ({
      all: 'initial',
      ...provided,
      ...tombac.text(),
      marginLeft: tombac.space(1),
      marginRight: tombac.space(1),
      paddingTop: '2px', // Fixes Noway font alignment
    }),
  };
}
