import styled from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { tombac } from '../../shared/tombac';

export interface NavbarCoreProps {
  breakpoint?: number;
}

export type NavbarProps = PropsWithPropStyling<NavbarCoreProps>;

export const Navbar = styled.div.attrs<NavbarProps>(() => ({
  className: 'TombacNavbar',
}))<NavbarProps>`
  align-items: center;
  background-color: ${tombac.color.white};
  box-shadow: 0 ${tombac.unit(2, 8)} 0 hsla(0, 0%, 0%, 0.04);
  display: flex;
  min-height: ${tombac.space(10)};
  position: relative;

  @media (max-width: ${({ breakpoint = 768 }) => tombac.unit(breakpoint)}) {
    min-height: ${tombac.space(8)};
  }

  ${propStyling}
`;
