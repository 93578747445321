import { alpha as alphaTool, color as colorTool } from './colorTools';
import { space as spaceTool, unit as unitTool } from './spaceTools';
import { tombacThemeColors } from '../cjs/tombacCommons';
import {
  ColorAlphaGrade,
  ColorGrade,
  PropsWithTheme,
  ThemeColor,
} from './tombacTypes';

function parseAlpha(value: string) {
  const matches = value.match(
    new RegExp(`^--(${tombacThemeColors.join('|')})-(i?)a(8|16|24|32|40|48)`),
  );
  if (!matches) {
    return () => value;
  }
  const color = matches[1] as ThemeColor;
  const inverted = Boolean(matches[2]);
  const alpha = matches[3]
    ? (Number(matches[3]) as ColorAlphaGrade)
    : undefined;
  if (!alpha) {
    return () => value;
  }
  return alphaTool(color, alpha, { inverted });
}

function parseColor(value: string) {
  const matches = value.match(
    new RegExp(`^--(${tombacThemeColors.join('|')})(-([1-9]00))?$`),
  );
  if (!matches) {
    return () => value;
  }
  const color = matches[1] as ThemeColor;
  const grade = matches[3] ? (Number(matches[3]) as ColorGrade) : undefined;
  return colorTool(color, grade);
}

function parseUnit(value: string) {
  const matches = value.match(/^(-?\d+(\.(\d+)?)?|\.\d+)([a-z]+)?$/);
  if (!matches) {
    return () => value;
  }
  const number = Number(matches[1]);
  const unit = matches[4];
  switch (unit) {
    case 'u':
      return unitTool(number);
    case 'sp':
      return spaceTool(number);
    default:
      return () => value;
  }
}

export const parse = (value: string) => (props: PropsWithTheme) => {
  return value
    .split(/\s+/)
    .map((v) => {
      v = parseAlpha(v)(props);
      v = parseColor(v)(props);
      v = parseUnit(v)(props);
      return v;
    })
    .join(' ');
};
