import { FC, useEffect } from 'react';
import gothamBoldEot from './font/gotham_bold.eot';
import gothamBoldOtf from './font/gotham_bold.otf';
import gothamBoldWoff from './font/gotham_bold.woff';
import gothamBookEot from './font/gotham_book.eot';
import gothamBookOtf from './font/gotham_book.otf';
import gothamBookWoff from './font/gotham_book.woff';
import gothamMediumEot from './font/gotham_medium.eot';
import gothamMediumOtf from './font/gotham_medium.otf';
import gothamMediumWoff from './font/gotham_medium.woff';

export const GothamFont: FC = () => {
  useEffect(() => {
    if (document.querySelector('style[data-tombac-style="gotham"]')) return;

    const styleElement = document.createElement('style');
    styleElement.setAttribute('data-tombac-style', 'gotham');
    styleElement.innerHTML = `@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url(${gothamBookEot});
  src: url(${gothamBookEot}?#iefix) format('embedded-opentype'),
    url(${gothamBookWoff}) format("woff"),
    url(${gothamBookOtf}) format("opentype");
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url(${gothamMediumEot});
  src: url(${gothamMediumEot}?#iefix) format('embedded-opentype'),
    url(${gothamMediumWoff}) format("woff"),
    url(${gothamMediumOtf}) format("opentype");
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url(${gothamBoldEot});
  src: url(${gothamBoldEot}?#iefix) format('embedded-opentype'),
    url(${gothamBoldWoff}) format("woff"),
    url(${gothamBoldOtf}) format("opentype");
}`;

    document.head.appendChild(styleElement);
  }, []);
  return null;
};
