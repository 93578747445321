import { resolveTheme } from '../cjs/tombacCommons';
import {
  ColorAlphaGrade,
  ColorGrade,
  PropsWithTheme,
  ThemeColor,
} from './tombacTypes';

export interface TombacColorInterpolationFunction {
  (props: PropsWithTheme): string;
}

export interface TombacColorTool {
  (color: ThemeColor, grade?: ColorGrade): TombacColorInterpolationFunction;
  black: TombacColorInterpolationFunction;
  white: TombacColorInterpolationFunction;
}

export const alpha = (
  color: ThemeColor,
  grade: ColorAlphaGrade,
  options?: { inverted: boolean },
) => ({ theme }: PropsWithTheme) => {
  const { colors } = resolveTheme(theme);
  if (options?.inverted && !colors[color].invertedAlpha)
    throw new Error(`Color "${color}" does not include inverted alpha palette`);
  return colors[color][options?.inverted ? 'invertedAlpha' : 'alpha']![grade];
};

export const color = ((color, grade = 500) => ({ theme }) =>
  resolveTheme(theme).colors[color][grade]) as TombacColorTool;

color.black = color('neutral', 900);
color.white = color('neutral', 100);
