import { pxToUnit, resolveTheme } from '../cjs/tombacCommons';
import { PropsWithTheme } from './tombacTypes';

export const space = (...sizes: number[]) => ({ theme }: PropsWithTheme) => {
  const { baseSpace, baseUnit } = resolveTheme(theme);
  return sizes.map(size => pxToUnit(size * baseSpace, baseUnit)).join(' ');
};

export const unit = (...px: number[]) => ({ theme }: PropsWithTheme) => {
  const { baseUnit } = resolveTheme(theme);
  const units = [];
  for (const pxArg of px) units.push(pxToUnit(pxArg, baseUnit));
  return units.join(' ');
};
