import { ThemeInterpolation, ThemeInterpolationFunction } from './tombacTypes';

export type VariantMap<Props> = {
  $default?: ThemeInterpolation<Props>;
} & {
  [variant in string | number]?: ThemeInterpolation<Props>;
};

export const variant = <Props extends {}>(
  prop: keyof Props,
  values: VariantMap<Props>,
): ThemeInterpolationFunction<Props> => {
  return (props: Props) => {
    const variant = props[prop];
    if (
      variant &&
      typeof variant === 'string' &&
      values.hasOwnProperty(variant)
    )
      return values[variant.toString()];
    return values.$default || {};
  };
};
