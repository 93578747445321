import { createPropStyling, PropStylingProps } from '../privatePropStylingUtils';

export const shadowCssAttrNames = ['boxShadow', 'textShadow'] as const;

export type ShadowCssAttrName = typeof shadowCssAttrNames[number];

export type ShadowPropStylingProps = PropStylingProps<ShadowCssAttrName>;

export const shadowPropStyling = createPropStyling<ShadowPropStylingProps>(
  shadowCssAttrNames,
);
