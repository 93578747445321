import React, { FC, SVGProps } from 'react';

export const TomTomLogo: FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 349 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <radialGradient
        cx="65.22%"
        cy="32.657%"
        fx="65.22%"
        fy="32.657%"
        r="101.036%"
        gradientTransform="scale(.97406 1) rotate(77.905 .66 .337)"
        id="logo_svg__a"
      >
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#FDFDFD" offset="37%" />
        <stop stopColor="#F6F6F6" offset="50%" />
        <stop stopColor="#EBEBEB" offset="60%" />
        <stop stopColor="#DADADA" offset="68%" />
        <stop stopColor="#C4C4C4" offset="74%" />
        <stop stopColor="#B3B3B3" offset="78%" />
        <stop offset="100%" />
      </radialGradient>
    </defs>
    <g fillRule="nonzero" fill="none">
      <path
        d="M316.59 1.54C303 4.36 294.28 17.43 297.18 30.68c2.9 13.25 16.31 21.72 29.91 18.9 13.6-2.82 22.3-15.89 19.41-29.13-2.89-13.24-16.32-21.73-29.91-18.91"
        fill="url(#logo_svg__a)"
        transform="translate(0 -1)"
      />
      <path
        d="M35.56 6.67H2.07C.69 6.67 0 9.07 0 11.48c0 2.41.69 4.8 2.07 4.8h11.81V43c0 1.35 2.47 2 4.94 2s4.93-.67 4.93-2V16.28h11.81c1.38 0 2.08-2.4 2.08-4.8s-.7-4.81-2.08-4.81zM39.39 25.67c.34-10.723 9.131-19.24 19.86-19.24s19.52 8.517 19.86 19.24c-.34 10.723-9.131 19.24-19.86 19.24s-19.52-8.517-19.86-19.24zm19.86 9.73c5.447.072 9.923-4.283 10-9.73 0-5.523-4.477-10-10-10s-10 4.477-10 10a9.86 9.86 0 0010 9.73zM143 22.82a16.71 16.71 0 00-17-16.49 17.11 17.11 0 00-12 4.85 17.11 17.11 0 00-12-4.85c-9.223-.111-16.794 7.267-16.92 16.49V43c0 1.35 2.47 2 4.94 2S95 44.34 95 43V22.82a7.07 7.07 0 0114.13 0V43c0 1.35 2.47 2 4.93 2 2.46 0 4.94-.67 4.94-2V22.82a7.07 7.07 0 0114.13 0V43c0 1.35 2.46 2 4.93 2 2.47 0 4.94-.67 4.94-2V22.82zM289 22.82c-.12-9.221-7.689-16.601-16.91-16.49a17.15 17.15 0 00-12 4.85 17.09 17.09 0 00-12-4.85 16.71 16.71 0 00-16.93 16.49V43c0 1.35 2.47 2 4.93 2 2.46 0 4.94-.67 4.94-2V22.82a7.07 7.07 0 0114.13 0V43c0 1.35 2.46 2 4.93 2 2.47 0 4.94-.67 4.94-2V22.82a7.06 7.06 0 0114.12 0V43c0 1.35 2.47 2 4.94 2s4.93-.67 4.93-2L289 22.82zM181.68 6.67h-33.49c-1.39 0-2.08 2.4-2.08 4.81 0 2.41.69 4.8 2.08 4.8H160V43c0 1.35 2.46 2 4.93 2 2.47 0 4.94-.67 4.94-2V16.28h11.81c1.38 0 2.07-2.4 2.07-4.8s-.69-4.81-2.07-4.81zM185.46 25.67c0-10.68 8.9-19.34 19.87-19.34 10.821-.139 19.707 8.519 19.85 19.34-.148 10.817-9.033 19.469-19.85 19.33-10.97 0-19.87-8.65-19.87-19.33zm19.87 9.73a9.86 9.86 0 0010-9.73c0-5.523-4.477-10-10-10s-10 4.477-10 10a9.86 9.86 0 0010 9.73z"
        fill="#010101"
      />
      <path
        d="M328.56 35.41a2.5 2.5 0 00-3.19.3l-.12.1c-1.74 1.56-4.64 4.15-7 3.62-1.73-.39-1.94-1.91-1.83-3.41 0-.39.07-.78.12-1.15.04-.3.09-.58.15-.84.22-1.1 2.06-9 3-13a5.62 5.62 0 00.2-1.89c-.12-.86-.83-1.16-1.79-1.4a2.65 2.65 0 00-3.1 1.47c-.45 1.49-1 3.52-2.21 9.63a9.41 9.41 0 01-.25.94v-.05c-.21.65-.43 1.31-1.33 1.13-.9-.18-.88-1.13-.91-1.8v-.14l-.06-1.52a73.3 73.3 0 010-7.41l.09-1.58.12-2.6c0-.72 0-1.09-.43-1.4a2 2 0 00-1.65-.24 2.76 2.76 0 00-1.52.76 2.66 2.66 0 00-.56 1.5c-.13 1.32-.22 2.65-.29 4a88.934 88.934 0 000 8v.54c0 1.34-.18 1.79-.93 1.83-.75.04-1.06-.71-1.21-1.29l-.1-.4c-.32-1.55-.6-3.12-.82-4.65l-.16-.93c-.27-1.58-.45-2.63-.61-4 0-.28-.13-.78-.51-.95a1.26 1.26 0 00-1.13.15 2.62 2.62 0 00-1.29 2.27c0 1.2.13 2.62.27 3.81a60.51 60.51 0 001.17 6.79 1.55 1.55 0 000 .21c.12.65.09 1.11-.19 1.32a.76.76 0 01-.65.06c-.48-.16-.92-1-1.1-1.4v-.09a43.47 43.47 0 01-1.81-5.34v-.13s-.11-.36-.41-.42-.57 0-1 .51c-.64.78-.12 2.68.09 3.75 1.11 5.51 5.64 14.13 14 17.38C316 50 320 46.79 328.12 39.86a5 5 0 001.61-2.37 1.41 1.41 0 00-.32-1.22 5.33 5.33 0 00-.85-.86z"
        fill="#EE2924"
      />
      <path
        d="M347 15.77c-2.49-7.2-6.73-10.41-7.45-11a9.36 9.36 0 00-2.43-1.46 3.1 3.1 0 00-.68-.1h-.07a.77.77 0 00-.33.06.3.3 0 00-.13.3c.194.5.523.936.95 1.26a40 40 0 013.51 3.76c.36.5.54 1.13 0 1.47a.77.77 0 01-.74 0 3.48 3.48 0 01-.74-.57 76.13 76.13 0 00-8.39-6.75 3.83 3.83 0 00-3.05-.65 1.18 1.18 0 00-.75.54c-.17.41.28.83.28.83 1.11 1 6.5 5.57 7.42 6.59.92 1.02.89 1.48.55 1.82-.49.49-1.26 0-1.47-.1h.06a5.29 5.29 0 01-.63-.44 92.51 92.51 0 00-9.91-6.4c-1.51-.73-2-1-3.4-.26 0 0-.87.44-.93 1.12a1.32 1.32 0 00.7 1.21c5.71 3.77 6.94 4.4 11.47 7.73.34.26 1.15 1 .42 1.68a1 1 0 01-.91.16A5.11 5.11 0 01329 16a78.3 78.3 0 00-9.09-4.47 2.5 2.5 0 00-3.14.78 2.33 2.33 0 00-.46 1.83c.14.59.75 1 1.15 1.2 3.21 1.81 5.69 2.88 8.85 4.78 1 .59 1.16.67 2.13 1.3 1.12.73 2.83 1.79 3.24 3.14.104.288.155.593.15.9a2 2 0 01-1.06 1.82c-1.54 1-4.29.72-6.7.56-.5 0-1.38-.09-1.9-.09-1.26 0-1.93.3-2.31.87a3.78 3.78 0 00-.37 1.85c-.01.15-.01.3 0 .45.022.206.098.403.22.57a4.33 4.33 0 002.94 1.07c2.24.12 4.6.17 7.33.22 6.4.12 13.65-.26 16.49-4.05 2.1-2.73 1.96-8.73.53-12.96z"
        fill="#EE2924"
      />
    </g>
  </svg>
);
