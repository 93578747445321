import React, {
  ElementType,
  FC,
  ReactNode,
  TransitionEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  AcceptIcon,
  CancelIcon,
  ErrorIcon,
  InfoIcon,
  SpinnerIcon,
  StarIcon,
  WarningIcon,
} from 'tombac-icons';
import { ThemeColor, tombac } from '../../shared';
import { Button } from '../Button';
import { VFlex } from '../Styling';
import { Label, Text } from '../Typography';
import { ToastOptions, ToastType } from './toastTypes';

export interface ToastInfo {
  dismissRequested?: boolean;
  id: string;
  message: ReactNode;
  options: ToastOptions;
  type: ToastType;
}

type ToastState = 'added' | 'entering' | 'entered' | 'exiting' | 'exited';

const ToastButton = styled(Button).attrs(() => ({
  children: <CancelIcon />,
  variant: 'flat',
}))`
  align-self: stretch;
  border-left: ${tombac.unit(1)} solid ${tombac.color('neutral', 400)};
  color: ${tombac.color('neutral', 600)};
  font-size: inherit;
  height: auto;
  padding: 0;
  width: ${tombac.space(6)};
`;

const ToastContainer = styled.div<{
  $height?: number;
  animEdge: 'left' | 'right';
  state: ToastState;
  $width: string;
}>`
  all: initial;
  box-sizing: border-box;
  opacity: 0;
  width: ${({ $width }) => $width};
  transform: translateX(
    ${({ animEdge }) => `${animEdge === 'left' ? '-' : ''}100%`}
  );
  ${({ $height, animEdge, state }) =>
    $height &&
    css`
      height: ${() =>
        state === 'added' || state === 'exiting' ? 0 : $height + 'px'};
      opacity: ${() => (state === 'added' || state === 'exiting' ? 0 : 1)};
      transform: translateX(
        ${() =>
          state === 'added' || state === 'exiting'
            ? `${animEdge === 'left' ? '-' : ''}100%`
            : 0}
      );
      transition: transform 0.2s ${() => state === 'entering' && '0.2s'},
        opacity 0.2s ${() => state === 'entering' && '0.2s'},
        height 0.2s ${() => state === 'exiting' && '0.2s'};
      transition-timing-function: ease-out;
    `}
`;

const ToastLayout = styled.div<{
  barColor?: ThemeColor;
  toastType: ToastType;
  dismissable: boolean | undefined;
}>`
  all: initial;
  align-items: center;
  background-color: ${tombac.color.white};
  border: ${tombac.unit(1)} solid ${tombac.color('neutral', 400)};
  box-shadow: ${tombac.unit(0, 1, 14, 0)} ${' '} ${tombac.alpha('neutral', 8)};
  display: flex;
  margin-top: ${tombac.space(1)};
  position: relative;

  ${({ toastType, dismissable }) =>
    dismissable
      ? css`
          padding-left: ${toastType !== 'loading' ? '0' : tombac.space(0.5)};
        `
      : css`
          padding-left: ${toastType !== 'loading' ? '0' : tombac.space(0.5)};
          padding-right: ${toastType !== 'loading' ? '0' : tombac.space(3.5)};
        `}

  ${({ barColor }) =>
    barColor &&
    css`
      &::before {
        background-color: ${tombac.color(barColor)};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: ${tombac.unit(3)};
      }
    `}
`;

const ToastIcon = styled.div<{ $color?: ThemeColor; withContent: boolean }>`
  all: initial;
  color: ${({ $color }) => $color && tombac.color($color)};
  display: block;
  padding: ${tombac.space(1)};

  ${({ withContent }) =>
    withContent &&
    css`
      align-self: flex-start;
      margin-top: ${tombac.space(1)};
    `}
`;

const toastTypeIconMap: Record<ToastType, ElementType> = {
  alert: WarningIcon,
  danger: ErrorIcon,
  default: StarIcon,
  info: InfoIcon,
  success: AcceptIcon,
  loading: (props) => <SpinnerIcon spin {...props} />,
};

const toastTypeColorMap: Record<ToastType, ThemeColor | undefined> = {
  alert: 'alert',
  danger: 'danger',
  default: undefined,
  info: 'accent',
  success: 'success',
  loading: 'primary',
};

export const Toast: FC<ToastInfo & { onDissmised: () => void }> = ({
  dismissRequested = false,
  message,
  options: {
    autoDismiss: baseAutoDismiss,
    autoDismissDelay = 5000,
    content,
    dismissable: baseDismissable,
    icon,
    onDismiss,
    placement,
  },
  onDissmised,
  type,
}) => {
  const [state, setState] = useState<ToastState>('added');
  const [paused, setPaused] = useState(false);
  const isLoadingType = type === 'loading';

  const autoDismiss =
    baseAutoDismiss === undefined && !isLoadingType ? true : baseAutoDismiss;

  const dismissable =
    baseDismissable === undefined && !isLoadingType ? true : baseDismissable;

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const height = useMemo(() => containerRef?.getBoundingClientRect().height, [
    containerRef,
  ]);

  useEffect(() => {
    if (height !== undefined) {
      requestAnimationFrame(() => setState('entering'));
    }
  }, [height]);

  useEffect(() => {
    let timeout: number | undefined;
    if (autoDismiss && !paused) {
      timeout = window.setTimeout(dismiss, autoDismissDelay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [autoDismiss, autoDismissDelay, paused]);

  useEffect(() => {
    if (dismissRequested === true && state === 'entered') {
      dismiss();
    }
  }, [dismissRequested, state]);

  useEffect(() => {
    if (state === 'exited') {
      onDissmised();
    }
  }, [state]);

  const dismiss = () => {
    setState('exiting');
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleTransitionEnd: TransitionEventHandler = (event) => {
    const { propertyName } = event;
    setState((state) => {
      if (state === 'entering' && propertyName === 'transform') {
        return 'entered';
      } else if (state === 'exiting' && propertyName === 'height') {
        return 'exited';
      }
      return state;
    });
  };

  const Icon = toastTypeIconMap[type];
  const color = toastTypeColorMap[type];

  return (
    <ToastContainer
      $height={height}
      animEdge={placement!.includes('left') ? 'left' : 'right'}
      onTransitionEnd={handleTransitionEnd}
      ref={setContainerRef}
      state={state}
      $width={isLoadingType ? 'auto' : '100%'}
    >
      <ToastLayout
        barColor={isLoadingType ? undefined : color}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
        dismissable={dismissable}
        toastType={type}
      >
        <ToastIcon $color={color} withContent={!!content}>
          {icon ?? <Icon size="lg" />}
        </ToastIcon>
        <VFlex
          flex="1"
          mb={1.9}
          mt={1.9}
          pr={isLoadingType && dismissable ? 3 : 0}
        >
          <Label as="div">{message}</Label>
          {content && (
            <Text $mt="0.5sp" as="div" size="s">
              {content}
            </Text>
          )}
        </VFlex>{' '}
        {dismissable && <ToastButton onClick={dismiss} />}
      </ToastLayout>
    </ToastContainer>
  );
};
