import isSameDay from 'date-fns/is_same_day';
import DateInterval from '../DateInterval';

const isIntervalEdge = (
  day: Date,
  intervals: DateInterval[],
  edge: keyof DateInterval = 'start',
) => intervals.some(interval => isSameDay(day, interval[edge]));

export default isIntervalEdge;
