import isSameWeek from "date-fns/is_same_week";
import { dateToString } from "./dateToString";
import { Weekdays } from "./Weekdays";

export const DAYS_IN_A_WEEK = 7;
export const EMPTY_DAY = 0;

export function generateDaysForMonth(year: number, month: number) {
  // in JS Date object: 0 - stands for January, 11 - stands for December
  const dateMonth = month - 1;
  const firstDayOfMonthDate = new Date(Date.UTC(year, dateMonth));

  let day = 1;
  let date = firstDayOfMonthDate;

  const days = [];

  while (date.getUTCMonth() === dateMonth) {
    days.push(date);
    day += 1;
    date = new Date(Date.UTC(year, dateMonth, day));
  }

  return days;
}

export function addPadding<T>(weeks: T[][]): T[][] {
  return weeks.map((week, index) => {
    const zeros = new Array(DAYS_IN_A_WEEK - week.length).fill(EMPTY_DAY);

    if (index === 0) {
      return [...zeros, ...week];
    }
    return [...week, ...zeros];
  });
}

function convertMapToArrays<T>(map: Map<number, T[]>) {
  return Array.from(map).map(([, array]) => array);
}

export function divideIntoWeeks(
  days: Date[],
  options: { showFullDates?: boolean; weekStartsOn: Weekdays },
) {
  let weekNumber = 0;

  const { weeks } = days.reduce(
    (result, date) => {
      const { weeks: partialWeeks, previousDate } = result;

      const isSameWeekOptions = { weekStartsOn: options.weekStartsOn };

      const day = options.showFullDates ? date : date.getUTCDate();
      const dateLeft = dateToString(previousDate);
      const dateRight = dateToString(date);

      if (isSameWeek(dateLeft, dateRight, isSameWeekOptions)) {
        const oldWeek = partialWeeks.get(weekNumber) ?? [];
        const newWeek = [...oldWeek, day];
        return {
          weeks: partialWeeks.set(weekNumber, newWeek),
          previousDate: date,
        };
      }
      weekNumber += 1;
      return {
        weeks: partialWeeks.set(weekNumber, [day]),
        previousDate: date,
      };
    },
    {
      weeks: new Map<number, Array<number | Date>>(),
      previousDate: null as Date | null,
    },
  );

  return convertMapToArrays(weeks);
}
