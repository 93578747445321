import {
  propStylingCssAttrAliases,
  propStylingCssAttrNames,
  PropsWithPropStyling,
} from './propStyling';

function isStylingPropName(
  propName: string,
): propName is keyof PropsWithPropStyling {
  const attrName: any = propName.replace('$', '');
  return (
    propStylingCssAttrNames.includes(attrName) ||
    propStylingCssAttrAliases.hasOwnProperty(attrName)
  );
}

export function extractStylingProps<
  P extends PropsWithPropStyling & Record<string, any>
>(props: P) {
  const stylingProps: PropsWithPropStyling = {};
  const nonStylingProps: any = {};
  for (const key of Object.keys(props) as (
    | keyof PropsWithPropStyling
    | string
  )[]) {
    if (isStylingPropName(key)) {
      stylingProps[key] = props[key] as any;
    } else {
      nonStylingProps[key] = props[key];
    }
  }
  return [
    stylingProps,
    nonStylingProps as Exclude<P, PropsWithPropStyling>,
  ] as const;
}
