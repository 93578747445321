import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { TextStyleProps, textStyles } from '../../shared/textStyles';
import { tombac } from '../../shared/tombac';

export const Menu = styled.ul<PropsWithPropStyling>`
  all: initial;
  background-color: ${tombac.color.white};
  border-radius: ${tombac.unit(1)};
  box-shadow: ${tombac.unit(0, 1, 3, 0)} ${tombac.alpha('neutral', 16)};
  display: flex;
  flex-flow: column;
  padding: ${tombac.space(1, 0)};
  ${propStyling}
`;

export const MenuItem = styled.li`
  all: initial;
`;

export type MenuActionProps = TextStyleProps & {
  prepend?: ReactNode;
};

const Prepend = styled.span`
  all: initial;
  margin-right: ${tombac.space(1)};
  min-width: ${tombac.space(3)};
  text-align: center;
`;

export const MenuAction = styled.button.attrs<MenuActionProps>(
  ({ children, prepend }) => ({
    children: prepend ? (
      <>
        <Prepend>{prepend}</Prepend>
        {children}
      </>
    ) : (
      children
    ),
  }),
)<MenuActionProps>`
  all: initial;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  min-height: ${tombac.space(4)};
  padding: ${tombac.space(0.5, 2)};
  transition: background-color 0.1s ease-out;
  width: 100%;
  ${tombac.altText({ fontSize: 12, fontWeight: 'bold' })}
  ${textStyles}

  &:hover {
    background-color: ${tombac.color('neutral', 300)};
  }

  &:active {
    background-color: ${tombac.color('neutral', 400)};
  }
`;
