import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { alpha, color } from './tombac/colorTools';
import { parse } from './tombac/parseTool';
import { space, unit } from './tombac/spaceTools';
import { variant } from './tombac/styledTools';
import { altFontFamily, altText, fontFamily, text } from './tombac/textTools';
import { bindTombacStaticTool, bindTombacTool } from './cjs/tombacCommons';
import { TombacTheme } from './tombac/tombacTypes';
import { typography } from './tombac/typography';

export * from './tombac/tombacTypes';

export const tombac = {
  alpha,
  altFontFamily,
  altText,
  color,
  fontFamily,
  parse,
  space,
  text,
  typography,
  unit,
  variant,
};

export type Tombac = typeof tombac;

type StaticTool = typeof staticTools[number];
type ToolWithParams = Exclude<keyof Tombac, StaticTool | 'typography'>;

type BoundStatic<K extends StaticTool, R = ReturnType<Tombac[K]>> = R;

type BoundWithParams<
  K extends ToolWithParams,
  R = ReturnType<ReturnType<Tombac[K]>>
> = (...args: Parameters<Tombac[K]>) => R;

export type BoundTombac = { [key in StaticTool]: BoundStatic<key> } &
  { [key in ToolWithParams]: BoundWithParams<key> };

const staticTools = ['altFontFamily', 'fontFamily'] as const;

export function bindTombac(theme: Partial<TombacTheme>) {
  function isStaticTool(tool: string): tool is StaticTool {
    return staticTools.includes(tool as any);
  }

  const bound: Partial<BoundTombac> = {};

  for (const key of Object.keys(tombac) as (
    | StaticTool
    | ToolWithParams
    | 'typography'
  )[]) {
    if (key !== 'typography')
      if (isStaticTool(key))
        bound[key] = bindTombacStaticTool(tombac[key], theme);
      else
        bound[key] = bindTombacTool(tombac[key], theme) as BoundWithParams<
          ToolWithParams
        >;
  }

  return bound as BoundTombac;
}

export function useTombac() {
  return bindTombac(useContext<TombacTheme>(ThemeContext));
}
