import { css } from 'styled-components';
import { alpha, color, TombacColorInterpolationFunction } from './colorTools';
import { unit } from './spaceTools';
import { altText, text } from './textTools';
import {
  HeadingTypographyProps,
  TypographyProps,
  TypographyVariant,
} from './tombacTypes';

const variantColors: Record<
  TypographyVariant,
  TombacColorInterpolationFunction | undefined
> = {
  default: undefined,
  secondary: color('neutral', 600),
  inverted: color.white,
  disabled: alpha('neutral', 24),
  alert: color('alert', 600),
  danger: color('danger', 500),
  interactive: color('accent', 600),
};

const variantStyles = ({ variant }: TypographyProps) =>
  variant === 'interactive'
    ? css`
        border-radius: ${unit(1)};
        cursor: pointer;
        transition: 0.1s ease-out;
        transition-property: box-shadow, color;

        &:hover {
          color: ${color('accent', 700)};
        }

        &:active {
          color: ${color('accent', 800)};
        }

        &:focus {
          box-shadow: 0 0 0 ${unit(4)} ${color('accent', 200)};
        }
      `
    : {};

export const typography = {
  caption: css<TypographyProps>`
    cursor: default;
    ${({ variant = 'default', size }) =>
      text({
        color: variantColors[variant],
        fontSize: size === 's' ? 10 : 12,
      })}
    ${variantStyles}
  `,
  heading: css<HeadingTypographyProps>`
    cursor: inherit;
    ${({ level, variant = 'default' }) =>
      altText({
        color: variantColors[variant],
        fontSize: [32, 24, 20, 16, 14][level - 1],
        fontWeight: 500,
      })}
    ${variantStyles}
  `,
  label: css<TypographyProps>`
    cursor: default;
    ${({ variant = 'default', size }) =>
      altText({
        color: variantColors[variant],
        fontSize: size === 's' ? 10 : 12,
        fontWeight: 700,
      })}
    ${variantStyles}
  `,
  text: css<TypographyProps>`
    cursor: inherit;
    ${({ variant = 'default', size }) =>
      text({
        color: variantColors[variant],
        fontSize: size === 's' ? 12 : undefined,
      })}
    ${variantStyles}
  `,
};
