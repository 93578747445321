import React, { FC } from 'react';
import ReactModal, { Props } from 'react-modal';
import { ModalStyles } from './ModalStyles';
import { ModalWindow } from './ModalWindow';

interface ModalProps extends Props {
  animationType?: 'top-slide-in' | 'fade-in-scale';
}

export const Modal: FC<ModalProps> = ({
  bodyOpenClassName,
  className,
  closeTimeoutMS,
  children,
  overlayClassName,
  animationType,
  ...rest
}) => {
  const modifier = animationType ?? 'top-slide-in';
  return (
    <ReactModal
      bodyOpenClassName={bodyOpenClassName ?? 'TombacModal__body--open'}
      className={
        className ?? {
          base: `TombacModal TombacModal--${modifier}`,
          afterOpen: `TombacModal--after-open-${modifier}`,
          beforeClose: `TombacModal--before-close-${modifier}`,
        }
      }
      closeTimeoutMS={closeTimeoutMS ?? 1000}
      overlayClassName={
        overlayClassName ?? {
          base: `TombacModal__overlay TombacModal__overlay--${modifier}`,
          afterOpen: `TombacModal__overlay--after-open-${modifier}`,
          beforeClose: `TombacModal__overlay--before-close-${modifier}`,
        }
      }
      {...rest}
    >
      <ModalStyles />
      <ModalWindow>{children}</ModalWindow>
    </ReactModal>
  );
};
