import React from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { PropsWithPropStyling } from '../../shared/propStyling';
import { Select } from './Select';

export interface SimpleSelectCoreProps<ValueType> {
  options: Array<{ value: ValueType; label: React.ReactNode }>;
  onChange?: (value: ValueType) => void;
  value?: ValueType;
  invalid?: boolean;
  selectProps?: Omit<ReactSelectProps, 'options' | 'value' | 'onChange'>;
}

export type SimpleSelectProps<ValueType> = PropsWithPropStyling<
  SimpleSelectCoreProps<ValueType>
>;

export function SimpleSelect<T>({
  options,
  onChange = () => {},
  value,
  selectProps = {},
  ...rest
}: SimpleSelectProps<T>) {
  const selectedValue = React.useMemo(
    () => options.find((o) => o.value === value),
    [value],
  );
  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={({ value }: any) => onChange(value)}
      {...rest}
      {...selectProps}
    />
  );
}
