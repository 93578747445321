import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { tombac } from '../../shared';
import { Toast, ToastInfo } from './Toast';
import { ToastOptions, ToastPlacement, ToastType } from './toastTypes';

export interface ToastsContextValue {
  addToast(message: ReactNode, type?: ToastType, options?: ToastOptions): void;
  dismissToast(id: string): void;
}

export interface ToastsProviderProps {
  topOffset?: 'navbar';
  zIndex?: number;
}

const ToastsContext = createContext<ToastsContextValue>(
  new Proxy(
    {},
    {
      get() {
        throw new Error(
          'Toasts context not found! Use the ToastsProvider component.',
        );
      },
    },
  ) as ToastsContextValue,
);

export const ToastsConsumer = ToastsContext.Consumer;

export function useToasts() {
  return useContext(ToastsContext);
}

const ListContainer = styled.div<{
  placement: ToastPlacement;
  topOffset: ToastsProviderProps['topOffset'];
  zIndex?: number;
}>`
  all: initial;
  display: flex;
  flex-direction: column-reverse;
  padding: ${tombac.space(0, 2, 1)};
  position: fixed;
  width: ${tombac.unit(432)};
  z-index: ${({ zIndex }) => zIndex};

  ${({ placement, topOffset }) => {
    const attrs = placement.split('-');
    return css`
      align-items: ${attrs[1] === 'left' ? 'flex-start' : 'flex-end'};
      ${attrs[0]}: ${attrs[0] === 'top' && topOffset === 'navbar'
        ? tombac.unit(80)
        : 0};
      ${attrs[1]}: 0;
    `;
  }}
`;

const ToastList: FC<{
  onChange: (toasts: ToastInfo[]) => void;
  placement: ToastPlacement;
  toasts: ToastInfo[];
  topOffset: ToastsProviderProps['topOffset'];
  zIndex?: number;
}> = ({ onChange, placement, toasts, topOffset, zIndex }) => (
  <ListContainer topOffset={topOffset} placement={placement} zIndex={zIndex}>
    {toasts.map((t) => (
      <Toast
        key={t.id}
        {...t}
        onDissmised={() => onChange(toasts.filter((i) => i.id !== t.id))}
      />
    ))}
  </ListContainer>
);

export const ToastsProvider: FC<ToastsProviderProps> = ({
  children,
  topOffset,
  zIndex,
}) => {
  const [toasts, setToasts] = useState<
    {
      [placement in ToastPlacement]?: ToastInfo[];
    }
  >({});

  const idRef = useRef(0);

  const api = useMemo<ToastsContextValue>(
    () => ({
      addToast(
        message,
        type = 'default',
        { placement = 'top-right', ...options } = {},
      ) {
        setToasts((toasts) => {
          const cornerToasts = toasts[placement] ?? [];
          if (
            options.id !== undefined &&
            cornerToasts.find((t) => t.id === options.id)
          )
            return toasts;
          idRef.current++;
          return {
            ...toasts,
            [placement]: cornerToasts.concat({
              id: options.id ?? idRef.current.toString(),
              message,
              options: { placement, ...options },
              type,
            }),
          };
        });
      },

      dismissToast(id: string) {
        setToasts((toasts) => {
          const newToasts: typeof toasts = {};
          for (const [placement, cornerToasts] of Object.entries(toasts) as [
            ToastPlacement,
            ToastInfo[],
          ][]) {
            const index = cornerToasts!.findIndex((t) => t.id === id);
            if (index !== -1) {
              newToasts[placement] = [...cornerToasts];
              newToasts[placement]![index] = {
                ...cornerToasts[index],
                dismissRequested: true,
              };
            } else {
              newToasts[placement] = cornerToasts;
            }
          }
          return newToasts;
        });
      },
    }),
    [],
  );

  return (
    <>
      {(Object.keys(toasts) as ToastPlacement[]).map((placement) => (
        <ToastList
          key={placement}
          placement={placement}
          onChange={(cornerToasts) =>
            setToasts({ ...toasts, [placement]: cornerToasts })
          }
          toasts={toasts[placement]!}
          topOffset={topOffset}
          zIndex={zIndex}
        />
      ))}
      <ToastsContext.Provider value={api}>{children}</ToastsContext.Provider>
    </>
  );
};
